import React from 'react';
import { withRouter } from 'react-router-dom';
import {  Form } from 'antd';
import Footer from './main/Footer';

const Fail = props => {

    return (

                <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginBottom: 100,
                        }}
                    > 
                        <Form className="che-form">
                        <div style={{padding: '0 20px', fontSize: '28px', marginTop: '80px' }}>
                            <p align="center" style={{marginBottom: '20px'}}>На сервере ведутся технические работы</p>
                        </div>
                        </Form>

                    </div>
                    <Footer />
                </div>
                
    );
};

export default withRouter(props => <Fail {...props} />);
