import React, { PureComponent } from 'react'
import { Tooltip as Tooltip2, DatePicker, Tabs, Form, Table, Spin, Select, Input, Button, Space, notification } from 'antd'
import { useState, useEffect } from 'react';
import { API_getdata } from '../api/status';
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import PageTitle from '../components/PageTitle';
import { API_getproductall } from './../api/product'; 
import { API_getreportdata, API_getreportdata2 } from './../api/reports';
import { generateXlsx } from '../api/jsontoxlsx';

const AdminStatistic = (props) => {

    var moment = require('moment');

    const [loading, setLoading] = useState(false);
    const [userCount, setuserCount] = useState(undefined);

    const startDateOfTheYear = moment().startOf('year').format('YYYY-MM-DD');

    const endDateOfTheYear = moment().endOf('year').format('YYYY-MM-DD');

    const [startDate, setstartDate] = useState(startDateOfTheYear);
    const [endDate, setendDate] = useState(endDateOfTheYear);

    const [reportdata, setreportdata] = useState([]);

    const [reportdata2, setreportdata2] = useState([]);

    const [reportdata3, setreportdata3] = useState([]);

    const [idProd, setidProd] = useState(1);

    const [product, setproduct] = useState([]);

    const columns = [
        {
          title: 'Товар',
          dataIndex: 'rname',
          key: 'rname',
        },
        {
            title: 'Клиент',
            dataIndex: 'rdate',
            key: 'rdate',
          },
        {
          title: 'Количество заказов',
          dataIndex: 'rdata',
          key: 'rdata',
        },
        {
            title: 'Сумма',
            dataIndex: 'sum',
            key: 'sum',
          },
      ];


    useEffect(() => {

        setLoading(true)

        const json1 = {
            tp: "getUserCount",
            p1: 1,
        };

        setLoading(true)

        API_getdata(json1)
        .then(data => {
            if (data!=undefined) {
                setuserCount(data.dt)
            } 
        })
        .finally(() => setLoading(false));

    }, []);

    useEffect(() => {

        API_getreportdata({ startDate: startDate , endDate: endDate , idProd: idProd}).then(data => setreportdata(convert(data)));

        API_getreportdata2({ startDate: startDate , endDate: endDate , idProd: idProd, isMercury: 2}).then(data => setreportdata2(data));

        API_getreportdata2({ startDate: startDate , endDate: endDate , idProd: idProd, isMercury: 1}).then(data => setreportdata3(data));

    }, [startDate,endDate,idProd]);

    class CustomizedLabel extends PureComponent {
        render() {
            const {
                x, y, stroke, value,
            } = this.props;
    
            return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle" >{value}</text>;
        }
    }
    const {  RangePicker } = DatePicker;

    const colors = [
        '#00FF00',
        '#0033cc',
        '#996600',
        '#006600',
        '#007399'
    ];

    const getRandomColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    };

    function onDtChange(date,dateString) {
        setstartDate(dateString[0]);
        setendDate(dateString[1]);
      }

    useEffect(() => {

        setLoading(true)

        API_getproductall()
                .then(data => {
                    if (data!=undefined) {

                        setproduct(data)

                    }
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false));

    }, []);  

    const convert = toConvert => {
        const map = toConvert.reduce((r, {rdate, rname, rdata}) => {
          if (r.has(rdate))
            r.set(rdate, {...r.get(rdate), [rname]: rdata});
         else
                r.set(rdate, {rdate, [rname]: rdata});
          return r;
      }, new Map());
      return [...map.values()];
    };

    return (
        <Spin spinning={loading}>
        <Form> 
            <div>Количество клиентов : {userCount}</div>
            <PageTitle style={{ marginTop: 10 }} title="Заказы" />
            <Form.Item label="Период" style={{ marginRight : 10, marginTop : 10, marginBottom : 10 }}>
                <RangePicker onChange={onDtChange} defaultValue={[moment(startDateOfTheYear,'YYYY-MM-DD'), moment(endDateOfTheYear,'YYYY-MM-DD')]} />
            </Form.Item>
            <Form.Item label="Выберите" style={{ marginBottom: 0 }} >
                        <Select
                        value={idProd}
                        onChange={setidProd}
                        placeholder="Выберите"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {product && product.map(pr => (
                            <Select.Option key={pr.id} value={pr.id}>
                                {pr.name}
                            </Select.Option>
                        ))}
                            <Select.Option key={999} value={999}>
                                Все заказы
                            </Select.Option>
                        </Select>

            </Form.Item>
            <Form.Item>
                <div id="node-to-convert">
                    <ResponsiveContainer width="100%" height={400}>
                    
                        <LineChart
                            data={reportdata}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="8 8" />
                                <XAxis dataKey="rdate" padding={{ left: 30, right: 30 }} />
                                <YAxis padding={{ top: 30}}/>
                                <Tooltip />
                                <Legend />
                                {
                                product.map(repdata => { 
                                    return idProd==repdata.id ?
                                     <Line key={repdata.name} type="monotone" dataKey={repdata.name} stroke={getRandomColor()} label={<CustomizedLabel />}/>
                                    : null })}   
                                {idProd==999 && <Line key="Все заказы" type="monotone" dataKey="Все заказы" stroke={getRandomColor()} label={<CustomizedLabel />}/>}
                        </LineChart>
                        
                    </ResponsiveContainer>   
                </div>
            </Form.Item>
            <PageTitle style={{ marginTop: 10 }} title="Все заказы" />
            <Form.Item>
            <Table 
                pagination={false} 
                dataSource={reportdata2} 
                columns={columns} 
                size="small"
            />
            </Form.Item>
            <PageTitle style={{ marginTop: 10 }} title="Меркурий" />
            <Form.Item>
            <Table 
                pagination={false} 
                dataSource={reportdata3} 
                columns={columns} 
                size="small"
            />
            </Form.Item>
            <Button style={{marginTop : 20}} icon={<FileExcelOutlined />} onClick={() => generateXlsx(reportdata3,"Отчет(c "+startDate+" по "+endDate+").xlsx",columns,"title","key")}>
                Отчет меркурий
            </Button>
        </Form>
        </Spin>
    )
}

export default AdminStatistic;