import React from 'react'
import { Tabs, Form, Table, Spin, Select, Input, Button, Space, notification } from 'antd'
import { useState, useEffect } from 'react';
import { API_getproductall } from './../api/product'; 
import { PlusOutlined } from '@ant-design/icons';

const AdminProduct = (props) => {

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: 'Товар',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Цена',
            key: 'price',
            dataIndex: 'price',
        },
        {
            title: 'Тип',
            render: row => {
            return (    row.tp =="ogr" ? <p style={{ color: 'red' }}>Огр.</p>
                        : <p style={{ color: 'green' }}>Н/О</p>
            )
          }
        },
        {
            title: 'Статус',
            render: row => {
            return (    
                row.st ==2 ? <p style={{ color: 'red' }}>Неактивен</p>
                        : <p style={{ color: 'green' }}>Активен</p>
            )
          }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [product, setproduct] = useState([]);

    useEffect(() => {

        setLoading(true)

        API_getproductall()
                .then(data => {
                    if (data!=undefined) {
                        setproduct(data)
                    }
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false));

    }, []);
    
    return (
        <Spin spinning={loading}>
        <Form> 
            <div align="right" style={{ marginBottom: 10 }} >
                        <Button size = "small" icon={<PlusOutlined />} onClick={() => props.history.push(`/productadd`)} type="primary"> 
                            Добавить
                        </Button>
            </div>
            
            <Table
                columns={columns}
                dataSource={product}
                rowClassName="row-hover"
                pagination={false}
                onRow={pr => {
                    return {
                        onClick: () => props.history.push(`/product/${pr.id}`),
                    };
                }}
            />
        </Form>
        </Spin>
    )
}

export default AdminProduct;