import axios from './../utils/axios';
import { notification } from 'antd';
import {API_url} from './../api/const';

export const API_changepassword = (login, oldpass, newpass) => {
    return axios({
        method: 'POST',
        url: `ib/changepassword`,
        data: {
            login: login,
            oldpass: oldpass,
            newpass: newpass,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка при получении данных changepassword` });
                return [];
            }
        })
        .catch(function(error) {
        });
};

export const API_forgetpassword = (email) => {
        return axios({
            method: 'POST',
            url: `forgetpassword`,
            data: {
                email: email,
            },
        })
            .then(function(response) {
                if (response) {
                    return response.data;
                } else {
                    notification.error({ message: `Ошибка при получении данных, попробуйте позже` });
                    return [];
                }
            })
            .catch(function(error) {
            });
}