import React from 'react'
import PageTitle from './../components/PageTitle';
import { Select, InputNumber, Form, Input, Spin, Button, notification, Upload, message } from 'antd';
import { API_updateproduct, API_getdataproduct } from './../api/product';
import { useState, useEffect } from 'react';
import { LoadingOutlined, PlusOutlined, SaveOutlined, KeyOutlined, EditOutlined } from '@ant-design/icons';
import { API_getstatus } from '../api/status';
import { API_uploadfile, API_deletefile } from '../api/file'; 
import { API_url } from '../api/const';
import logo from './../images/logoIB.JPG';

const Product = (props) => {

    const [loading, setLoading] = useState(false);

    const [imageUrl, setimageUrl] = useState(undefined);

    const [objimage, setobjimage] = useState(undefined);

    const [tpId, settpId] = useState(undefined);

    const [stId, setstId] = useState(undefined);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    id: Number(props.match.params.id),
                    name: values.name,
                    description: values.description,
                    price: Number(values.price),
                    mr: values.mr,
                    link: objimage,
                    tp: tpId,
                    st: Number(stId),
                    ndoc: values.ndoc,
                };
                API_updateproduct(json)
                    .then(function(response) {
                        if (response!=undefined) {
                            if (response.id == 1) {
                                notification.success({ message: 'Данные сохранены' });
                                props.history.push('/market')
                            }
                    }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const getProductData = () => {
        API_getdataproduct(props.match.params.id)
            .then(response => {

                        setobjimage(response[0].link)

                        settpId(response[0].tp)

                        setstId(response[0].st)

                        setimageUrl(API_url+"/api/v1/download/"+response[0].link)
                    
                        form.setFieldsValue({
                            name: response[0].name,
                            description: response[0].description,
                            price: response[0].price,
                            mr: response[0].mr,
                            tp: response[0].tp,
                            st: Number(response[0].st),
                            ndoc: response[0].ndoc,
                        });
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
    };


    const [form] = Form.useForm();

    useEffect(() => {

        getProductData();

        const json = {
            userlogin : localStorage.getItem('login'),
        };

        API_getstatus(json)

        .then(data => {
            if (data!=undefined) {
                if (data[0].userType != 1) {
                    localStorage.removeItem('accessToken')
                    props.history.push('/login')
                }
            }
        })
    }, []);

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('Вы можете загрузить только JPG/PNG файлы');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Изображение должно быть меньше 5MB');
        }
        return isJpgOrPng && isLt2M;
      }

      const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status === 'done') {
        var reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => {

            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

            const json = {
                id: Number(props.match.params.id),
                fileBody: fileSource,
            };

            API_uploadfile(json)
            .then(function(response) {

                setimageUrl(API_url+"/api/v1/download/"+response.IdFile)

                if (objimage!='-') {
                    API_deletefile(objimage)
                }

                setobjimage(response.IdFile)
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
        }
        setLoading(false);
        }
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };
  


    return (
            <Spin spinning={loading}>
            
                <Form className="che-form" onFinish={handleSubmit} form={form} layout='vertical'>
                    <PageTitle title="Редактирование продукта" />
                    
                    <Form.Item label="Продукт" name="name" rules={[{required: true, message: 'введите имя'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Короткое описание" name="description" rules={[{required: true, message: 'введите описание'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Нормативный документ" name="ndoc" rules={[{required: true, message: 'введите нормативный документ'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Цена" name="price" rules={[{required: true, message: 'введите цену'}]}>
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="Мера" name="mr" rules={[{required: true, message: 'введите меру'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Тип" name="tp" rules={[{required: true, message: 'выберите тип'}]}>
                        <Select
                        value={tpId}
                        onChange={settpId}
                        placeholder="Выберите тип"
                        >
                            <Select.Option key="no" value="no">
                                Не ограничен
                            </Select.Option>
                            <Select.Option key="ogr" value="ogr">
                                Ограничен
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Статус" name="st" rules={[{required: true, message: 'выберите статус'}]}>
                        <Select
                        value={stId}
                        onChange={setstId}
                        placeholder="Выберите статус"
                        >
                            <Select.Option key={1} value={1}>
                                Активен
                            </Select.Option>
                            <Select.Option key={2} value={2}>
                                Не активен
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <div style={{ marginBottom: 10, marginTop: 10, width: '250px', width: '200px' }}>
                        <Upload 
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {objimage!='-' ? 
                            <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} />
                            : 
                            <img alt="example" src={logo} style={{ width: '100%', height: '100%' }}/>}
                        </Upload>
                    </div>
                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                            Сохранить
                    </Button> 

                </Form>
            </Spin>
    )
}

export default Product;
