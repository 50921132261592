import React from 'react'
import { Image, Form, Spin, List, Card, InputNumber, Button, notification ,message } from 'antd';
import { API_getstatus } from '../api/status';
import { useState, useEffect } from 'react'; 
import PageTitle from '../components/PageTitle';
import { ShoppingCartOutlined, PlusOutlined } from '@ant-design/icons';
import { API_getproduct } from '../api/product';
import { API_cartAdd, API_GetCartCnt } from '../api/cart';
import CartTitle from '../components/CartTitle';
import { API_url } from '../api/const';
import logo from './../images/logoIB.JPG';

const Title = (props) => {

    const [godMode, setgodMode] = useState(false);
    const [product, setproduct] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cartCnt, setcartCnt] = useState(0);

    const [userStatus, setuserStatus] = useState(undefined);

    const [update, setupdate] = useState(false);

    const counts = [];

    const [cnt, setcnt] = useState([]);

    useEffect(() => {

        setLoading(true)

        const json = {
            userlogin : localStorage.getItem('login'),
        };

        API_getstatus(json)

        .then(data => {
            if (data!=undefined) {
                
                if (data[0].siteVersion != localStorage.getItem('siteVersion')) {
                    localStorage.setItem('siteVersion', data[0].siteVersion);
                    window.location.reload();
                }
                if (data[0].userStatus == 0) {
                    localStorage.removeItem('accessToken')
                    props.history.push('/login')
                }
                if (data[0].userType == 1) {
                    setgodMode(true)
                }
            }
        })

        API_getproduct()
                .then(data => {
                    if (data!=undefined) {
                        setproduct(data)
                        data.map((data, key) => {
                            counts[data.id] = 1;
                        })
                        setcnt(counts)
                    }
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false));

    }, []);

    useEffect(() => {
        const json = {
            login : localStorage.getItem('login'),
        };

        API_GetCartCnt(json)

        .then(data => {
 
            setcartCnt(data.id)

        })

    }, [update]); 


    const onChangeCount = (id) => value => {

        if (value>0) {
            cnt[id] = value;
        }
    
    }

    const addCart = (id) => {

        const json = {
            login : localStorage.getItem('login'),
            productId : id,
            cnt : cnt[id],
        };

        API_cartAdd(json)
                    .then(function(response) {
                        if (response!=undefined) {
                            if (response.id == 1) {
                                message.success('Добавлено в корзину');
                                setupdate(!update)
                            }
                    }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));

        
    }

    return (
            <Spin spinning={loading}>
                <Form className="che-form">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 20,
                            marginBottom: 10,
                        }}
                    >
                    <PageTitle title="Магазин" />
                    <CartTitle cartCnt={cartCnt}/>
                    </div>
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 4,
                            xxl: 4,
                          }}
                        dataSource={product}
                        renderItem={item => (
                        <List.Item>
                            <Card 
                                cover={item.link=='-' ? <div align="center" ><Image width={200} src={logo}/></div> : <div align="center" ><Image width={200} src={API_url+"/api/v1/download/"+item.link}/></div>}
                                title={<div><font size="2"><p>{item.name}</p></font></div>} 
                                style={{ width: "100%" }}
                                className="bli-main-card"
                            >
                                <div>
                                    <div>
                                        <font size="1"><p><em>{item.ndoc}</em></p></font>
                                        <font size="1"><p><em>{item.description}</em></p></font>
                                    </div>
                                    <div align="right" >
                                        <p style={{ marginTop: 5, marginBottom: 5 }} >Цена: <b>{item.price}</b> руб. за {item.mr}</p>
                                        <p>
                                            <InputNumber style={{ width: 60 }} size = "small" min={1} max={10000} defaultValue={1} onChange={onChangeCount(item.id)} />
                                            <Button size = "small" type="primary" style={{ marginTop: 10, marginLeft: 5 }} onClick={()=>{addCart(item.id)}}><ShoppingCartOutlined style={{ marginRight: 5 }}/>В корзину</Button>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </List.Item>
                        )}
                    />
                </Form>
            </Spin>    
    )
}

export default Title;