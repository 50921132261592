import React from 'react'
import { connect } from 'react-redux'
import {  Button } from 'antd'
import {  SettingOutlined, GlobalOutlined, UserOutlined, PoweroffOutlined} from '@ant-design/icons';
import { Link, NavLink} from 'react-router-dom' 
import logo from './../../images/logoIB.JPG';

const Header = props => {

    const logout = () => {
        localStorage.removeItem('accessToken')
        props.history.push('/login')
    }

    return (
        <div
        style={{
            height: 100,
            background: '#FFF',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 20,
            color: '#4C4D5A',
        }}
    >
        <p style={{ fontSize: 20 }}>
            <NavLink to="/">
                <img src={logo} width="100px" height="100px" alt="Logo" />
            </NavLink>
        </p>


        <p style={{ fontSize: 16, marginRight: 20 }}>
            <Link to={`/profile/${localStorage.getItem('login')}`} style={{ marginRight: 10 }}>
                <SettingOutlined style={{ marginRight: 10 },{ color : "green" }} />
                <UserOutlined style={{ marginRight: 10 }} />
                {props.login}
            </Link>
            <Button icon={<PoweroffOutlined />} shape="circle" size="small" onClick={logout} />
        </p>

    </div>

    )
}
const mapStateToProps = state => {
    return {
        login: state.user.login,
    }
}

export default connect(mapStateToProps)(Header)
