import React from 'react'
import { Input, Radio, Select, Modal, Tooltip as Tooltip2, Collapse, Button, Form, Table, Spin, DatePicker, Popconfirm, notification, InputNumber, Row, Space } from 'antd'
import PageTitle from '../components/PageTitle';
import { useState, useEffect } from 'react'; 
import { API_orderupdate2, API_orderUpdate, API_deleteOrderDetail, API_getordersall, API_getorderdetail, API_setorderstatus, API_adminOrder} from '../api/order';
import { EditOutlined, PrinterOutlined, SaveOutlined, PlusOutlined, PlusSquareOutlined, DeleteOutlined, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { API_getdata } from '../api/status'; 
import { API_printdocx, API_printdocx2, API_printdocx3 } from '../api/print';
import { API_getproductall, API_addProductAdm } from './../api/product';
import { API_cartAdd } from '../api/cart';
import { API_createorder } from '../api/order'; 
import { API_getdataprofileall } from '../api/profile';
import { API_getdotadds } from './../api/dotadds';

const AdminOrders = (props) => {

    var moment = require('moment');

    const { Panel } = Collapse;

    const [loading, setLoading] = useState(false);

    const [ordersData, setordersData] = useState([]);

    const [orderDetail, setorderDetail] = useState([]);

    const [update, setupdate] = useState(false);

    const [orderSum, setorderSum] = useState(undefined); 

    const [userData, setuserData] = useState(undefined); 

    const [idProd, setidProd] = useState(undefined);

    const [product, setproduct] = useState([]);

    const [orderDate, setorderDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

    const [modalVisible, setmodalVisible] = useState(false);

    const [modalVisible2, setmodalVisible2] = useState(false);

    const [modalVisibleAddOrder, setmodalVisibleAddOrder] = useState(false);

    const [oCode, setoCode] = useState(undefined);

    const [stat, setstat] = useState(undefined); 

    const [dDate, setdDate] = useState(undefined);

    const [users, setusers] = useState([]); 

    const [marketlogin, setmarketlogin] = useState(undefined);

    const [orderCount, setorderCount] = useState(undefined); 

    const [dotAddsSt, setdotAddsSt] = useState(undefined);

    const [dotAdds, setdotAdds] = useState([]); 

    function modalVis() {
            setmodalVisible(!modalVisible)
    }

    function modalVis2(dt,adds,opl,comment,orderStatusId) {
        setmodalVisible2(!modalVisible2)

        setdDate(dt)

        form2.setFieldsValue({
            dDate: moment(dt, 'YYYY-MM-DD'),
            adds: adds,
            opl: opl,
            comment: comment,
            orderStatusId: orderStatusId,
        })
    }

    const resetModal = () => {
        setmodalVisible(!modalVisible)
        form.resetFields();
    };

    const resetModalAddOrder = () => {
        setmodalVisibleAddOrder(!modalVisibleAddOrder)
        form3.resetFields();
    };

    function modalVisAddOrder(dt) {
        setmodalVisibleAddOrder(!modalVisibleAddOrder)
        setdDate(moment(dt).format('YYYY-MM-DD'))
    }

    const resetModal2 = () => {
        setmodalVisible2(!modalVisible2)
        form2.resetFields();
    };

    useEffect(() => {

        setLoading(true)

        API_getproductall()
                .then(data => {
                    if (data!=undefined) {
                        setproduct(data)
                    }
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false));

        }, []);
    
      function confirm(id,orderCode) {
        setLoading(true);
                const json = {
                    id: id,
                };
                API_deleteOrderDetail(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Удалено' });
                            
                            callback(orderCode)

                            setupdate(!update)
                        }

                        if (response.id==2) {
                            notification.error({ message: 'Нельзя удалить последний пункт, отмените заказ' });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
      }

    const onChangeCount = (id,orderCode) => value => {

    if (value>0) {

        setLoading(true)

        const json = {
            id: id,
            cnt: value,
        };

        API_orderUpdate(json)
        .then(data => {

                callback(orderCode)
                setupdate(!update)

        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
            throw Error;
        })
        .finally(() => setLoading(false));
    }

    }  


    const genExtra = (status) => {
        let cl
        let statusSt
        if (status == 1) {
            cl="green"
            statusSt="Новый заказ"
        }
        if (status == 2) {
            cl="red"
            statusSt="Заказ отменен"
        }
        if (status == 3) {
            cl="#F10BF5"
            statusSt="В работе"
        }
        if (status == 4) {
            cl="blue"
            statusSt="Выполнен"
        }
        return (
            <p style={{color: cl }}>{statusSt}</p>
        );
    }

    function callback(key) {
        if (key!=undefined) {

            setoCode(key)

            const json1 = {
                tp: "getUserByOrderId",
                p1: key,
            };
    
            setLoading(true)
    
            API_getdata(json1)
            .then(data => {
                if (data!=undefined) {
                    setuserData(data.dt)
                } 
            })
            .finally(() => setLoading(false));

            const json2 = {
                tp: "getStatByOrderCode",
                p1: key,
            };
    
            setLoading(true)
    
            API_getdata(json2)
            .then(data => {
                if (data!=undefined) {
                    setstat(data.dt)
                } 
            })
            .finally(() => {
                setLoading(false)
            });


            setLoading(true);
            const json = {
                orderCode: key,
            };
            API_getorderdetail(json)
                .then(function(response) {
                    if (response!=undefined) {
                        setorderDetail(response)
                        setorderSum(response[0].smA)
                        setorderCount(response[0].cnt)
                        form11.setFieldsValue({
                            orderCnt: response[0].cnt
                        });
                    }
                })
            .finally(() => setLoading(false));

        }
        
      }

    const columns = [
        {
          title: 'Товар',
          dataIndex: 'productName',
          key: 'productName',
        },
        {
            title: 'Цена руб.',
            dataIndex: 'price',
            key: 'price',
          },
          {
                    title: 'Количество',
                    render: row => {
                      return (
                          <div>
                            <p>{row.cnt}</p>
                          </div>
                      )
                    }
        },
        {
          title: '+/-',
          render: row => {
            return (    
                <div>
                {stat ==3 || stat ==1 ?
                <Form.Item name="orderCnt">
                    <InputNumber size = "small" min={1} max={10000} defaultValue={row.cnt} onChange={onChangeCount(row.orderDetailId,row.orderCode)} />
                </Form.Item>
                    :<p>{row.cnt}</p>
                }
                </div>
            )
          }
        },
        {
            title: 'Сумма руб.',
            dataIndex: 'sm',
            key: 'sm',
        },
        {
            title: 'Действия',
            render: row => {
            return ( 
                <div>
                {stat !=4 && stat !=2 && 
                <Tooltip2 title="Удалить">
                    <Popconfirm title="Вы действительно хотите удалить？" placement="bottom" onConfirm={() => confirm(row.orderDetailId,row.orderCode)} okText="Да" cancelText="Нет">
                        <a><DeleteOutlined style={{ color: 'red' }}/></a>   
                    </Popconfirm>
                </Tooltip2>
                }
                </div>
            )
          }
        },
    ];  



    useEffect(() => {

        const json = {
            orderDate: orderDate,
        };

        setLoading(true)

        API_getordersall(json)

        .then(data => {
            if (data!=undefined) {
                setordersData(data)
            }
        })
        .finally(() => setLoading(false));

    }, [update]); 

    function orderStatus(orderCode,status) {
        setLoading(true);
                const json = {
                    orderCode: orderCode,
                    status: status,
                };
                API_setorderstatus(json)
                    .then(function(response) {
                        if (response.id==1) {
                            if (status==1) {
                                notification.success({ message: 'Отменен' });
                                setupdate(!update)
                            }
                            if (status==2) {
                                props.history.push('/cart')
                                setupdate(!update)
                            }
                            if (status==4) {
                                setupdate(!update)
                            }
                            if (status==3) {
                                setupdate(!update)
                            }
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
      }

    function onChange(date, dateString) {

        setorderDate(dateString);

        setupdate(!update)

    }

    function printdocx(orderId,docType,orderCode,orderStatusId,fileName) {

        console.log(docType)

        setLoading(true);

        if (orderStatusId==1) {

            orderStatus(orderCode,3/*в работе*/)

        }
        API_printdocx(orderId,docType,fileName).then(() => 
                setLoading(false)
        );

    }

    function printdocx2(dDate) {

        setLoading(true);

        API_printdocx2(dDate).then(() => 
                {
                    setLoading(false)
                    setupdate(!update)
                }
                
        );

    }

    function printdocx3(dDate) {

        setLoading(true);

        API_printdocx3(dDate).then(() => 
                {
                    setLoading(false)
                    setupdate(!update)
                }
                
        );

    }

    const handleSubmitAddOrder = values => {
        setLoading(true);

        console.log()
        
        const json = {
            login : marketlogin,
            order : values.orders,
            dDate: dDate,
            adds: dotAddsSt,
        };
        API_adminOrder(json)
        .then(function(response) {
            if (response!=undefined) {
                notification.success({ message: 'Заказ добавлен' });
                resetModalAddOrder();
        }
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
            throw Error;
        })
        .finally(() => {
            setLoading(false)
            setupdate(!update)
        })
        ;
    };

    const [form] = Form.useForm();

    const [form2] = Form.useForm();

    const [form3] = Form.useForm();

    const [form11] = Form.useForm();

    const handleSubmit = values => {
        setLoading(true);
        const json = {
            productId: Number(values.id),
            oCode: oCode,
        };
            API_addProductAdm(json)
            .then(function(response) {
                    if (response.id==1) {
                        notification.success({ message: 'Товар добавлен' });
                    }
                    resetModal()
                    callback(oCode)
                    setupdate(!update)
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
            .finally(() => setLoading(false))
        ;
    };

    const handleSubmit2 = values => {
        setLoading(true);
        const json = {
            oCode: oCode,
            dDate: dDate,
            opl: values.opl,
            adds:  values.adds,
            comment:  values.comment,
            orderStatusId: values.orderStatusId,
        };
        API_orderupdate2(json)
            .then(function(response) {
                    if (response.id==1) {
                        notification.success({ message: 'Сохранен' });
                        resetModal2()
                        setupdate(!update)
                    } 
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
            .finally(() => setLoading(false))
        ;
    };

    function ondDateChange(date, dateString) {

        setdDate(dateString);

      }

    useEffect(() => {
  
        setLoading(true)

        API_getdataprofileall()
        .then(data => {
            if (data!=undefined) {
                setusers(data)
            }
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
            throw Error;
        })
        .finally(() => setLoading(false));
  
    }, []);

    useEffect(() => {

        setLoading(true)

        API_getdotadds(marketlogin)
        .then(data => {
            if (data!=undefined) {
                setdotAdds(data)
            } 
        })
        .finally(() => setLoading(false));

    }, [marketlogin]);

    return (
        <>
        <Modal
        okButtonProps={{ style: { display: 'none' } }}
        title={`Добавление заказа`}
        onCancel={resetModalAddOrder}
        visible={modalVisibleAddOrder}
        centered
        >
            <Form form={form3} onFinish={handleSubmitAddOrder} layout='vertical'>
                <Spin spinning={loading}>
                    <Form.Item label="Выберите магазин" style={{ marginBottom: 0 }} 
                    name="marketlogin" rules={[{required: true, message: 'Выберите магазин'}]}>
                        <Select
                        value={marketlogin}
                        onChange={setmarketlogin}
                        onChange={value => {
                            setmarketlogin(value);
                            setdotAddsSt(undefined);
                            form3.setFieldsValue({ dotAdds: undefined });
                        }}
                        placeholder="Выберите магазин"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {users && users.map(pr => (
                            <Select.Option key={pr.email} value={pr.email}>
                                {pr.magazin}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Выберите адрес доставки" style={{ marginBottom: 20 }}
                    name="dotAdds" rules={[{required: true, message: 'Выберите адрес доставки. Если адреса нет то добавьте его в настройке профиля магазина'}]}>
                        <Select
                        value={dotAddsSt}
                        onChange={setdotAddsSt}
                        placeholder="Выберите адрес доставки"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {dotAdds && dotAdds.map(pr => (
                            <Select.Option key={pr.dotAdds} value={pr.dotAdds}>
                                {'Адрес: '+pr.dotAdds+' Доп.инфо: '+pr.dotName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.List name="orders">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 5,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'product']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Выберите товар',
                                                },
                                            ]}
                                        >
                                            <Select
                                                value={idProd}
                                                onChange={setidProd}
                                                placeholder="Выберите продукт"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                            >
                                                {product && product.map(pr => (
                                                    <Select.Option key={pr.id} value={pr.id}>
                                                        {pr.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'count']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Введите количество',
                                                },
                                            ]}
                                        >
                                            <InputNumber min={1} max={10000} placeholder="Количество"/>
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Добавить товар
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Button icon={<PlusSquareOutlined />} style={{marginTop : 20}} htmlType="submit" type="primary">
                        Добавить заказ
                    </Button>
                </Spin>    
            </Form>
                
        </Modal>
        <Modal
        okButtonProps={{ style: { display: 'none' } }}
        title={`Добавление продукта`}
        onCancel={resetModal}
        visible={modalVisible}
        centered
        >
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Spin spinning={loading}>
                    <Form.Item label="Выберите продукт" style={{ marginBottom: 0 }} 
                    name="id" rules={[{required: true, message: 'Выберите продукт'}]}>
                        <Select
                        value={idProd}
                        onChange={setidProd}
                        placeholder="Выберите продукт"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {product && product.map(pr => (
                            <Select.Option key={pr.id} value={pr.id}>
                                {pr.name}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Button icon={<PlusSquareOutlined />} style={{marginTop : 20}} htmlType="submit" type="primary">
                        Добавить
                    </Button>
                </Spin>    
            </Form>
                
        </Modal>
        <Modal
        okButtonProps={{ style: { display: 'none' } }}
        title={`Редактирование заказа`}
        onCancel={resetModal2}
        visible={modalVisible2}
        centered
        >
            <Form form={form2} onFinish={handleSubmit2} layout='vertical'>
                <Spin spinning={loading}>
                    <Form.Item style={{ marginTop: 10 }} label="Изменить дату доставки:" name="dDate" rules={[{required: true, message: 'выберите дату доставки'}]} >
                    <DatePicker 
                        format="YYYY-MM-DD"
                        onChange={ondDateChange} />
                    </Form.Item>
                    <Form.Item style={{ marginTop: 10 }} label="Изменить адрес доставки:" name="adds" rules={[{required: true, message: 'введите адрес'}]} >
                        <Input maxLength = {255} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Изменить тип оплаты" style={{ marginBottom: 10 }} 
                    name="opl" rules={[{required: true, message: 'выберите тип оплаты'}]}>
                        <Radio.Group >
                            <Radio value={1}>Наличный расчет</Radio> 
                            <Radio value={2}>Безналичный расчет</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Статус заказа" style={{ marginBottom: 10 }} 
                    name="orderStatusId" rules={[{required: true, message: 'выберите статус заказа'}]}>
                        <Radio.Group >
                            <Radio value={1}>Новый заказ</Radio> 
                            <Radio value={2}>Отменен</Radio>
                            <Radio value={3}>В работе</Radio>
                            <Radio value={4}>Выполнен</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item style={{ marginTop: 10 }} label="Комментарий к заказу" name="comment" rules={[{required: false, message: 'введите адрес'}]} >
                        <Input maxLength = {255} autoComplete="off"/>
                    </Form.Item>
                    <Button icon={<SaveOutlined />} style={{marginTop : 20}} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
                
        </Modal>       
        <Spin spinning={loading}>
            <Form form={form11} >
                <PageTitle style={{ marginTop: 20, marginBottom: 10 }} title="Заказы" />
                <Form.Item label="Выберите дату :" name="orderDate">
                <DatePicker 
                    format="YYYY-MM-DD"
                    onChange={onChange} 
                    defaultValue={moment(orderDate, 'YYYY-MM-DD')}
                />
                    
                </Form.Item>
                <Row>
                <Button size = "small" icon={<PrinterOutlined />} onClick={() => printdocx2(orderDate)} type="primary" style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}}>
                    Печать накладных
                </Button>

                <Button size = "small" icon={<PrinterOutlined />} onClick={() => printdocx3(orderDate)} type="primary" style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}}>
                    Печать счетов
                </Button>

                <Button size = "small" icon={<PlusOutlined />} onClick={() => modalVisAddOrder(orderDate)} type="primary" style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}}> 
                    Добавить заказ
                </Button>
                </Row>
                <Collapse accordion
                onChange={callback}
                >

                {ordersData.map(dt => (

                    <Panel header={`Заказ № ${dt.orderId} от ${dt.orderDate}. Заказчик: ${dt.magazin} Адрес: ${dt.adds}`} key={dt.orderCode} extra={genExtra(dt.orderStatusId)}>
                        
                        <p style={{ marginBottom: 5, marginTop: 5 }} >Заказчик: {userData}</p>    
                        <p style={{ marginBottom: 5, marginTop: 5 }} >Адрес: {dt.adds}</p>
                        <p style={{ marginBottom: 5, marginTop: 5 }} >Оплата: {dt.opl==1 ? "Наличный расчет" : "Безналичный расчет"}</p> 

                        <div align="right">
                            
                            <p style={{ marginBottom: 5, marginTop: 5 }} >
                                <div>
                                    
                                    <Button icon={<PrinterOutlined />} onClick={() => printdocx(dt.orderId,dt.docType,dt.orderCode,dt.orderStatusId,`Заказ № ${dt.orderId} от ${dt.orderDate}. Заказчик: ${dt.magazin} Адрес: ${dt.adds}`)} size="small" style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}}>
                                        Печать
                                    </Button>

                                    <Button icon={<EditOutlined />} style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}} size="small" onClick={() => modalVis2(dt.dDate,dt.adds,dt.opl,dt.comment,dt.orderStatusId)}>
                                        Редактировать
                                    </Button>

                                </div>
                                
                                {dt.orderStatusId==3 &&
                                    <Popconfirm title="Отметить как выполнен？" placement="bottom" onConfirm={() => orderStatus(dt.orderCode,4/*выполнен*/)} okText="Да" cancelText="Нет">
                                    <Button  size="small" style={{color : "green", marginLeft: 5}}>
                                        <CheckCircleOutlined />{` `}Выполнен
                                    </Button>
                                    </Popconfirm>
                                }
                                {dt.orderStatusId!=4 && dt.orderStatusId!=2 &&
                                <div>
                                    <Button icon={<PlusOutlined />} style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}} size="small" onClick={() => modalVis()}>
                                        Добавить товар
                                    </Button>
                                    <Popconfirm title="Отменить заказ？" placement="bottom" onConfirm={() => orderStatus(dt.orderCode,1/*отмена*/)} okText="Да" cancelText="Нет">
                                        <Button size="small" style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}} danger>
                                            Отменить заказ
                                        </Button>
                                    </Popconfirm>
                                </div>
                                }
                                
                            </p>
                        </div>
                            <Table 
                                pagination={false} 
                                dataSource={orderDetail} 
                                columns={columns} 
                                size="small"
                                footer={() => <div align="right" ><b>Общая сумма:{` `+orderSum+` `} рублей</b></div> }
                            />
                        <p style={{ marginBottom: 5, marginTop: 5 }} >Комментарий: {dt.comment}</p>
                    </Panel>

                ))}
        
                </Collapse>

            </Form>
        </Spin>
        </>
    )
}

export default AdminOrders;