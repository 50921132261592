import React from 'react'
import PageTitle from './../components/PageTitle';
import { Select, InputNumber, Form, Input, Spin, Button, notification, Upload, message } from 'antd';
import { API_addproduct } from './../api/product';
import { useState, useEffect } from 'react';
import { LoadingOutlined, PlusOutlined, SaveOutlined, KeyOutlined, EditOutlined } from '@ant-design/icons';
import { API_getstatus } from '../api/status';

const ProductAdd = (props) => {

    const [loading, setLoading] = useState(false);
    const [tpId, settpId] = useState(undefined);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    name: values.name,
                    description: values.description,
                    price: Number(values.price),
                    mr: values.mr,
                    tp: values.tp,
                    ndoc: values.ndoc,
                };
                API_addproduct(json)
                    .then(function(response) {
                        if (response!=undefined) {
                            if (response.id == 1) {
                                notification.success({ message: 'Продукт добавлен' });
                                props.history.push('/market')
                            }
                    }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const [form] = Form.useForm();

    useEffect(() => {

        const json = {
            userlogin : localStorage.getItem('login'),
        };

        API_getstatus(json)

        .then(data => {
            if (data!=undefined) {
                if (data[0].userType != 1) {
                    localStorage.removeItem('accessToken')
                    props.history.push('/login')
                }
            }
        })
    }, []);


    return (
            <Spin spinning={loading}>
            
                <Form className="che-form" onFinish={handleSubmit} form={form} layout='vertical'>
                    <PageTitle title="Добавление продукта" />
                    
                    <Form.Item label="Продукт" name="name" rules={[{required: true, message: 'введите имя'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Короткое описание" name="description" rules={[{required: true, message: 'введите описание'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Нормативный документ" name="ndoc" rules={[{required: true, message: 'введите нормативный документ'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Цена" name="price" rules={[{required: true, message: 'введите цену'}]}>
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="Мера" name="mr" rules={[{required: true, message: 'введите меру'}]}>
                        <Input maxLength = {250} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item label="Тип" name="tp" rules={[{required: true, message: 'выберите статус'}]}>
                        <Select
                        value={tpId}
                        onChange={settpId}
                        placeholder="Выберите тип"
                        >
                            <Select.Option key="no" value="no">
                                Не ограничен
                            </Select.Option>
                            <Select.Option key="ogr" value="ogr">
                                Ограничен
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                            Добавить
                    </Button> 

                </Form>
            </Spin>
    )
}

export default ProductAdd;
