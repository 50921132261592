import React from 'react'
import { YoutubeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

const Footer = (props) => (
    <div style={{ paddingLeft: 20, paddingRight: 20, height: 100, display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '##4C4D5A', flex: '0 0 auto', color: '#B2ABAB' }}>
        <p style={{ marginRight: 20 }}>© ИП Борисов С. С.</p>

        <div>
            <p>Email: <font color="#0099ff"><a href="mailto:sales@ipborisov.ru">sales@ipborisov.ru</a></font></p>
        </div>
    </div>
)

export default Footer
