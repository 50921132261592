import React from 'react';
import { Form, Input, Button, Modal, notification, Drawer } from 'antd';
import { API_changepassword } from './../api/changepassword';
import { useState } from 'react';

const ChangePassword = props => {

    const [DrawerV, setDrawerV] = useState(true);

    const  compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('newpass')) {
        callback('введенные вами пароли не совпадают!');
    } else {
        callback();
    }
    };

    const onFinish = values => {
                API_changepassword(localStorage.getItem('login'), values.oldpass, values.newpass)
                    .then(response => {
                        if (response.id=="1") {
                            setDrawerV(false)
                            info()
                        }
                        if (response.id=="0") {
                            notification.error({ message: `Вы неверно ввели старый пароль` });
                        }
                    })
                    .catch(
                        setDrawerV(false)
                    );
    };

    const DrawerClose = () => {
        setDrawerV(false)
      };

    function DeleteToken() {
        localStorage.removeItem('accessToken')
        window.location.reload();
    };

    function info() {
        Modal.info({
          title: 'Смена пароля',
          content: (
            <div>
              <p>Вы успешно поменяли пароль, теперь вы можете авторизоватся с новым паролем.</p>
            </div>
          ),
          onOk() {DeleteToken()},
        });
      }

    const [form] = Form.useForm();  

    return (
            <Drawer
            width={"80%"}
            title="Смена пароля"
            visible={DrawerV}
            onClose={DrawerClose}
            bodyStyle={{ paddingBottom: 80 }}
            >
            
            <Form onFinish={onFinish} layout='vertical' form={form}>  
            <Form.Item label="Старый пароль" name="oldpass" rules={[{required: true, message: 'Пожалуйста введите пароль'}]} hasFeedback>
                <Input type="password" />
            </Form.Item>

            <Form.Item label="Новый пароль" name="newpass" rules={[{required: true, message: 'Пожалуйста введите новый пароль'}]} hasFeedback>
                <Input type="password" />
            </Form.Item>

            <Form.Item label="Повторите новый пароль" name="confirmnewpass" rules={[{required: true, message: 'Пожалуйста повторите ваш новый пароль',validator: compareToFirstPassword}]} hasFeedback>
                <Input.Password />
            </Form.Item>
            <Button type="primary" htmlType="submit">
                    Поменять пароль
            </Button>
            </Form>  
            </Drawer>
    );
};

export default ChangePassword;