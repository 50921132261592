import axios from './../utils/axios';
import { notification } from 'antd';

export const API_registration = (
    name, email, phone, password, inn, kpp, sity, adds, rs, ks, bid, bname, docType, type, magazin, mercury) => {
    return axios({
        method: 'POST',
        url: `registration`,
        data: {
            name: name, 
            email: email, 
            phone: phone, 
            password: password, 
            inn: inn,
            kpp: kpp, 
            sity: sity, 
            adds: adds,
            rs: rs, 
            ks: ks, 
            bid: bid, 
            bname: bname,
            docType: docType,
            type: type,
            magazin: magazin,
            mercury: mercury,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};

export const API_checkreg = (email) => {
    return axios({
        method: 'POST',
        url: `checkreg`,
        data: {
            email: email,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};