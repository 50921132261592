import React from 'react'
import { Collapse, Button, Form, Table, Spin, Tooltip as Tooltip2, Popconfirm, notification, InputNumber } from 'antd'
import PageTitle from '../components/PageTitle';
import { useState, useEffect } from 'react'; 
import { API_getorders, API_getorderdetail, API_setorderstatus } from '../api/order';
import {  SyncOutlined } from '@ant-design/icons';

const Order = (props) => {

    const { Panel } = Collapse;

    const [loading, setLoading] = useState(false);

    const [ordersData, setordersData] = useState([]);

    const [orderDetail, setorderDetail] = useState([]);

    const [update, setupdate] = useState(false);

    const [orderSum, setorderSum] = useState(undefined);

    const columns = [
        {
          title: 'Товар',
          dataIndex: 'productName',
          key: 'productName',
        },
        {
            title: 'Цена руб.',
            dataIndex: 'price',
            key: 'price',
          },
        {
          title: 'Количество',
          dataIndex: 'cnt',
          key: 'cnt',
        },
        {
            title: 'Сумма руб.',
            dataIndex: 'sm',
            key: 'sm',
          },
      ];


    function confirm(cartId) {
/*
        setLoading(true);
                const json = {
                    cartId: cartId,
                };
                API_deletecart(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Удалено' });
                            setupdate(!update)
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
                    */
      }

    const genExtra = (status) => {
        let cl
        let statusSt
        if (status == 1) {
            cl="green"
            statusSt="Заказ принят"
        }
        if (status == 2) {
            cl="red"
            statusSt="Заказ отменен"
        }
        if (status == 3) {
            cl="#F10BF5"
            statusSt="В работе"
        }
        if (status == 4) {
            cl="blue"
            statusSt="Выполнен"
        }
        return (
            <p style={{color: cl }}>{statusSt}</p>
        );
    }

    function callback(key) {
        if (key!=undefined) {
            setLoading(true);
            const json = {
                orderCode: key,
            };
            API_getorderdetail(json)
                .then(function(response) {
                    if (response!=undefined) {
                        setorderDetail(response)
                        setorderSum(response[0].smA)
                    }
                })
            .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
            .finally(() => setLoading(false));
        }
        
      }



    useEffect(() => {

        setLoading(true)

        API_getorders()

        .then(data => {
            if (data!=undefined) {
                setordersData(data)
            }
        })
        .finally(() => setLoading(false));

    }, [update]);

    function orderStatus(orderCode,status) {
        setLoading(true);
                const json = {
                    orderCode: orderCode,
                    status: status,
                };
                API_setorderstatus(json)
                    .then(function(response) {
                        if (response.id==1) {
                            if (status==1) {
                                notification.success({ message: 'Отменен' });
                                setupdate(!update)
                            }
                            if (status==2) {
                                props.history.push('/cart')
                                setupdate(!update)
                            }
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
      }


    return (
        <Spin spinning={loading}>
            <Form className="che-form">

                <PageTitle style={{ marginTop: 20 }} title="Заказы" />

                <Collapse accordion
                onChange={callback}
                >

                {ordersData.map(dt => (

                    <Panel header={`Заказ № ${dt.orderId} от ${dt.orderDate}. Доставка: ${dt.dDate}`} key={dt.orderCode} extra={genExtra(dt.orderStatusId)}>
                        <p style={{ marginBottom: 5, marginTop: 5 }} >Адрес доставки: {dt.adds}</p>
                        <p style={{ marginBottom: 5, marginTop: 5 }} >Оплата: {dt.opl==1 ? "Наличный расчет" : "Безналичный расчет"}</p>   
                        <div align="right">

                            <p style={{ marginBottom: 5, marginTop: 5 }} >

                                <Popconfirm title="Повторить заказ？" placement="bottom" onConfirm={() => orderStatus(dt.orderCode,2/*повтор*/)} okText="Да" cancelText="Нет">
                                <Button size="small" style={{color : "green"}}>
                                    <SyncOutlined />{` `}Повторить заказ
                                </Button>
                                </Popconfirm>

                                {dt.orderStatusId==1 &&
                                    <Popconfirm title="Отменить заказ？" placement="bottom" onConfirm={() => orderStatus(dt.orderCode,1/*отмена*/)} okText="Да" cancelText="Нет">
                                        <Button size="small" style={{ marginLeft: 5, marginBottom: 5, marginTop: 5}} danger>
                                            Отменить заказ
                                        </Button>
                                    </Popconfirm>
                                }

                            </p>
                        </div>
                            
                        <Table 
                            pagination={false} 
                            dataSource={orderDetail} 
                            columns={columns} 
                            size="small"
                            footer={() => <div align="right" ><b>Общая сумма:{` `+orderSum+` `} рублей</b></div> }
                        />
                        <p style={{ marginBottom: 5, marginTop: 5 }} >Комментарий: {dt.comment}</p>
                    </Panel>

                ))}
        
                </Collapse>

            </Form>
        </Spin>
    )
}

export default Order;