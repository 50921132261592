import axios from './../utils/axios';
import { notification } from 'antd';

export const API_createorder = json => {
    return axios({
        method: 'POST',
        url: `ib/createorder`,
        data: {
            dDate: json.dDate,
            adds: json.adds,
            comment: json.comment,
            opl: json.opl,
            login: json.login,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getorders = json => {
    return axios({
        method: 'GET',
        url: `ib/getorders`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getorderdetail = json => {
    return axios({
        method: 'GET',
        url: `ib/getorderdetail/${json.orderCode}`,
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};

export const API_setorderstatus = (json) => {
    return axios({
        method: 'POST',
        url: `ib/setorderstatus`,
        data: {
            orderCode: json.orderCode,
            orderStatusId: json.status,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};

export const API_getordersall = json => {
    return axios({
        method: 'GET',
        url: `ib/getordersall/${json.orderDate}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_orderUpdate = json => {
    return axios({
        method: 'POST',
        url: `ib/ordercntupdate`,
        data: {
            orderDetailId: json.id,
            cnt: json.cnt,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deleteOrderDetail = json => {
    return axios({
        method: 'DELETE',
        url: `ib/orderdetaildelete/${json.id}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
}; 

export const API_orderupdate2 = json => {
    return axios({
        method: 'POST',
        url: `ib/orderupdate`,
        data: {
            orderCode: json.oCode,
            dDate: json.dDate,
            opl: json.opl,
            adds:  json.adds,
            comment:  json.comment,
            orderStatusId:  json.orderStatusId,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_adminOrder = (json) => {
    return axios({
        method: 'POST',
        url: `ib/addorderadmin`,
        data: {
            login: json.login,
            order: json.order,
            dDate: json.dDate,
            adds: json.adds,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};