import React from 'react'
import { Row, Tooltip as Tooltip2, Button, Form, Table, Spin, DatePicker, Popconfirm, notification } from 'antd'
import { useState, useEffect } from 'react';
import { API_getholydays } from '../api/holyday'; 
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { API_getdata } from '../api/status';
import PageTitle from '../components/PageTitle';

const AdminHolydays = (props) => {

    var moment = require('moment');

    const [loading, setLoading] = useState(false);

    const [holydays, setholydays] = useState([]);
    const [update, setupdate] = useState(false);

    const [holyday, setholyday] = useState(undefined);

    const columns = [
        {
          title: 'Выходной день',
          dataIndex: 'hd',
          key: 'hd',
        },
        {
            title: 'Действия',
            render: row => {
            return ( 
                <Tooltip2 title="Удалить">
                    <Popconfirm title="Вы действительно хотите удалить？" placement="bottom" onConfirm={() => confirm(row.id)} okText="Да" cancelText="Нет">
                        <a><DeleteOutlined style={{ color: 'red' }}/></a>   
                    </Popconfirm>
                </Tooltip2>
            )
          }
        },
      ];


    function confirm(id) {
        setLoading(true);
                const json = {
                    tp: "deleteHolyday",
                    p1: id,
                };
                API_getdata(json)
                    .then(function(response) {
                        if (response.dt==1) {
                            notification.success({ message: 'Удалено' });

                            setupdate(!update)
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    }

    useEffect(() => {

        setLoading(true)

        API_getholydays()
                .then(data => {
                    if (data!=undefined) {
                        setholydays(data)

                    }
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false));

    }, [update]);

    function onChange(date, dateString) {

        setholyday(dateString);

        setupdate(!update)

    }

    const handleSubmit = values => {
        setLoading(true);
        const json = {
            tp: "addHolyday",
            p1: holyday,
        };
        API_getdata(json)
            .then(function(response) {
                    if (response.dt==1) {
                        notification.success({ message: 'Добавлен' });
                        setupdate(!update)
                    }  
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
            .finally(() => setLoading(false))
        ;
    };

    const [form] = Form.useForm();
    
    return (
        <Spin spinning={loading}>
            <Form form={form} onFinish={handleSubmit} className="che-form">
            <PageTitle style={{ marginTop: 10 }} title="Довавить выходной день" />
            <Row>
                <Form.Item label="Выберите выходной день :" name="orderDate" rules={[{required: true, message: 'Выберите день'}]}>
                    <DatePicker 
                        format="YYYY-MM-DD"
                        onChange={onChange} 
                />
                </Form.Item>
                <Button icon={<PlusOutlined />} style={{marginLeft : 5}} htmlType="submit" type="primary">
                    Добавить
                </Button>
            </Row>    
            <PageTitle style={{ marginTop: 10 }} title="Выходные дни" />   
            <Form.Item> 
                <Table
                    columns={columns}
                    dataSource={holydays}
                    rowClassName="row-hover"
                    pagination={false}
                    size="small"
                />
            </Form.Item>        
            </Form>    
        </Spin>
    )
}

export default AdminHolydays;