import axios from './../utils/axios';
import { notification } from 'antd';

export const API_updateprofile = json => {
    return axios({
        method: 'POST',
        url: `ib/updateprofile`,
        data: {
            name: json.name,
            inn: json.inn,
            kpp: json.kpp,
            sity: json.sity,
            adds: json.adds,
            phone: json.phone,
            email: json.email,
            status: json.status,
            rs: json.rs,
            ks: json.ks,
            bid: json.bid,
            bname: json.bname,
            docType: json.docType,
            magazin: json.magazin,
            mercury: json.mercury,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getdataprofile = login => {
    return axios({
        method: 'GET',
        url: `ib/getdataprofile/${login}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getdataprofileall = login => {
    return axios({
        method: 'GET',
        url: `ib/getdataprofileall`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};
