import React from 'react'
import { Tabs, Form, Table, Spin, Select, Input, Button, Space, notification } from 'antd'
import { useState, useEffect } from 'react';
import { API_getdataprofileall } from '../api/profile'; 
import { PlusOutlined } from '@ant-design/icons';

const AdminUsers = (props) => {

    const columns = [
        {
            title: 'Имя магазина',
            key: 'magazin',
            dataIndex: 'magazin',
        },
        {
            title: 'ИНН/Телефон/почта',
            render: row => {
            return (    
                <div>
                    <p>{row.inn}</p>
                    <p>{row.phone}</p>
                    <p>{row.email}</p>
                </div>
                )
          }
        },
        {
            title: 'Адрес',
            render: row => {
            return (    
                <div>
                    <p>{row.sity}</p>
                    <p>{row.adds}</p>
                </div>
                )
          }
        },
        {
            title: 'Статус',
            fixed: 'right',
            render: row => {
                let cl,text
                if (row.status =="ban") {
                    text="Заблокирован"
                    cl="red"
                }
                if (row.status =="act") {
                    text="Активен (А)"
                    cl="green"
                }
                if (row.status =="ogr") {
                    text="Активен (Б)"
                    cl="blue"
                }
            return (    
                    <p style={{ color: cl }}>{text}</p>
                )
          }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [users, setusers] = useState([]);

    useEffect(() => {

        setLoading(true)

        API_getdataprofileall()
                .then(data => {
                    if (data!=undefined) {
                        setusers(data)
                    }
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false));

    }, []);
    
    return (
        <Spin spinning={loading}>
            <Form>
            <div align="right" style={{ marginBottom: 10 }} >
                        <Button size = "small" icon={<PlusOutlined />} onClick={() => props.history.push(`/useradd`)} type="primary"> 
                            Добавить пользователя
                        </Button>
            </div>
                <Table
                    columns={columns}
                    dataSource={users}
                    rowClassName="row-hover"
                    pagination={false}
                    size="small"
                    onRow={user => {
                        return {
                            onClick: () => props.history.push(`/profile/${user.email}`),
                        };
                    }}
                />
            </Form>    
        </Spin>
    )
}

export default AdminUsers;