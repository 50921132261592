import React from 'react'
import { Checkbox, Row, Form, Spin, notification } from 'antd'
import { useState, useEffect } from 'react'; 
import { API_getdata } from '../api/status';
import PageTitle from '../components/PageTitle';

const AdminPays = (props) => {

    const [loading, setLoading] = useState(false);

    const [update, setupdate] = useState(true);

    const [Checked1, setChecked1] = useState(true);
    const [Checked2, setChecked2] = useState(true);
    

    useEffect(() => {

        const json1 = {
            tp: "getPay1",
            p1: 1,
        };

        setLoading(true)

        API_getdata(json1)
        .then(data => {
            if (data!=undefined) {
                if (Number(data.dt)==1) {
                    setChecked1(true)
                } else {
                    setChecked1(false)
                }
            } 
        })
        .finally(() => setLoading(false));

        const json2 = {
            tp: "getPay2",
            p1: 1,
        };

        setLoading(true)

        API_getdata(json2)
        .then(data => {
            if (data!=undefined) {
                if (Number(data.dt)==1) {
                    setChecked2(true)
                } else {
                    setChecked2(false)
                }
            } 
        })
        .finally(() => setLoading(false));

    }, [update]);

    function onChange1(e) {

    if (e.target.checked==true) {
        const json1 = {
            tp: "setPay1",
            p1: 1,
        }; 
        setLoading(true)

        API_getdata(json1)
        .then(data => {
            if (data!=undefined) {
                if (data.dt==1) {
                    notification.success({ message: 'Статус обновлен' });
                    setupdate(!update)
                }
            } 
        })
        .finally(() => setLoading(false));
    }
    else {
        const json1 = {
            tp: "setPay1",
            p1: 2,
        }; 
        setLoading(true)

        API_getdata(json1)
        .then(data => {
            if (data!=undefined) {
                if (data.dt==1) {
                    notification.success({ message: 'Статус обновлен' });
                    setupdate(!update)
                }
            } 
        })
        .finally(() => setLoading(false));
    }
    }

    function onChange2(e) {
        if (e.target.checked==true) {
            const json1 = {
                tp: "setPay2",
                p1: 1,
            }; 
            setLoading(true)
    
            API_getdata(json1)
            .then(data => {
                if (data!=undefined) {
                    if (data.dt==1) {
                        notification.success({ message: 'Статус обновлен' });
                        setupdate(!update)
                    }
                } 
            })
            .finally(() => setLoading(false));
        }
        else {
            const json1 = {
                tp: "setPay2",
                p1: 2,
            }; 
            setLoading(true)
    
            API_getdata(json1)
            .then(data => {
                if (data!=undefined) {
                    if (data.dt==1) {
                        notification.success({ message: 'Статус обновлен' });
                        setupdate(!update)
                    }
                } 
            })
            .finally(() => setLoading(false));
        }
    }
    
    return (
        <Spin spinning={loading}>
            <Form className="che-form">
            <PageTitle style={{ marginTop: 10 }} title="Способы оплаты" />
            <Row>
                <Checkbox checked={Checked1} onChange={onChange1}>Наличный расчет</Checkbox>
                <Checkbox checked={Checked2} onChange={onChange2}>Безналичный расчет</Checkbox>
            </Row>         
            </Form>    
        </Spin>
    )
}

export default AdminPays;