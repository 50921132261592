import axios from './../utils/axios';
import { notification } from 'antd';

export const API_getreportdata = (json) => {
    let url = `ib/getreportdata?`;
    if (json) {
        if (json.startDate) url += `&startDate=${json.startDate}`;
        if (json.endDate) url += `&endDate=${json.endDate}`;
        if (json.idProd) url += `&idProd=${json.idProd}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getreportdata2 = (json) => {
    let url = `ib/getreportdata2?`;
    if (json) {
        if (json.startDate) url += `&startDate=${json.startDate}`;
        if (json.endDate) url += `&endDate=${json.endDate}`;
        if (json.idProd) url += `&idProd=${json.idProd}`; 
        if (json.isMercury) url += `&isMercury=${json.isMercury}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

