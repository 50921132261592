import React from 'react'
import PageTitle from './../components/PageTitle';
import { Modal, Table, Popconfirm, Tooltip, Form, Input, Spin, Button, notification, Select } from 'antd';
import { API_updateprofile, API_getdataprofile } from './../api/profile';
import { useState, useEffect } from 'react';
import ChangePassword from './../components/ChangePassword';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, KeyOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { API_getstatus } from '../api/status';
import { API_getdotadds, API_adddotadds, API_deletedotadds } from './../api/dotadds';

const Profile = (props) => {

    const [dis, setdis] = useState(true);

    const [loading, setLoading] = useState(false);

    const [DrawerV, setDrawerV] = useState(false);

    const [godMode, setgodMode] = useState(false);

    const [statusId, setstatusId] = useState(undefined); 

    const [docTypeId, setdocTypeId] = useState(undefined);

    const [mercury, setmercury] = useState(undefined);

    const [modalVisible, setmodalVisible] = useState(false);

    const [update, setupdate] = useState(false); 

    const [dotadds, setdotadds] = useState([]);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    name: values.name,
                    inn: values.inn,
                    kpp: values.kpp,
                    sity: values.sity,
                    adds: values.adds,
                    phone: values.phone,
                    email: props.match.params.login,
                    status: statusId,
                    rs: values.rs,
                    ks: values.ks,
                    bid: values.bid,
                    bname: values.bname,
                    docType: docTypeId,
                    magazin: values.magazin,
                    mercury: mercury,
                };
                API_updateprofile(json)
                    .then(function(response) {
                        if (response!=undefined) {
                            if (response.id == 1) {
                                notification.success({ message: 'Данные сохранены' });
                            }
                    }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const getProfileData = () => {
        API_getdataprofile(props.match.params.login)
            .then(response => {
                        form.setFieldsValue({
                            name: response[0].name,
                            inn: response[0].inn,
                            kpp: response[0].kpp,
                            sity: response[0].sity,
                            adds: response[0].adds,
                            phone: response[0].phone,
                            email: response[0].email,
                            status: response[0].status,
                            rs: response[0].rs,
                            ks: response[0].ks,
                            bid: response[0].bid,
                            bname: response[0].bname,
                            docType: response[0].docType,
                            magazin: response[0].magazin,
                            mercury: response[0].mercury,
                        });
                        setstatusId(response[0].status)
                        setdocTypeId(response[0].docType)
                        setmercury(response[0].mercury)
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
    };

    const vizDrawerV = () => {

        setDrawerV(!DrawerV)

    }

    const [form] = Form.useForm();

    useEffect(() => {
        getProfileData();
        if (props.match.params.login==localStorage.getItem('login')) {
            setdis(false)
        }
        const json = {
            userlogin : localStorage.getItem('login'),
        };

        API_getstatus(json)

        .then(data => {
            if (data!=undefined) {
                if (data[0].siteVersion != localStorage.getItem('siteVersion')) {
                    localStorage.setItem('siteVersion', data[0].siteVersion);
                    window.location.reload();
                }
                if (data[0].userStatus == 0) {
                    localStorage.removeItem('accessToken')
                    props.history.push('/login')
                }
                if (data[0].userType == 1) {
                    setgodMode(true)
                    setdis(false)
                }
                if (data[0].userType != 1) {
                    if (props.match.params.login!=localStorage.getItem('login')) {
                        localStorage.removeItem('accessToken')
                        props.history.push('/login')
                    }
                }
            }
        })
    }, []);

    const columns = [
        {
            title: 'Адрес',
            key: 'dotAdds',
            dataIndex: 'dotAdds',
        },
        {
            title: 'Дополнительные сведения',
            key: 'dotName',
            dataIndex: 'dotName',
        },
        {
            title: 'Действия',
            render: row => {
            return (    
                <>
                <Tooltip title="Удалить">
                    <Popconfirm title="Вы действительно хотите удалить？" placement="bottom" onConfirm={() => deletedotadds(row.id)} okText="Да" cancelText="Нет">
                        <a><DeleteOutlined style={{ color: 'red' }}/></a>   
                    </Popconfirm>
                </Tooltip>
                </>
            )
            },
        },
    ];

    function deletedotadds(id) {
        setLoading(true);
                API_deletedotadds(id)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Удален' });
                            setupdate(!update)
                        }
                        if (response.id!=1) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    }        

    const [form2] = Form.useForm();

    const resetModal = () => {
        setmodalVisible(!modalVisible)
        form2.resetFields();
    };


    function modalVis() {
        setmodalVisible(!modalVisible)
    }

    
    const handleSubmit2 = values => {
        setLoading(true)
        const json = {
            dotname: values.dotname,
            dotadds: values.dotadds,
            login: props.match.params.login,
            };
        API_adddotadds(json)
        .then(data => {
            if (data.id==1) {
                notification.success({ message: 'Адрес добавлен' });
                setupdate(!update)
            }
            if (data.id!=1) {
                notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
            }
        })
        .finally(setLoading(false));

        setmodalVisible(!modalVisible)
    }

    useEffect(() => {

        setLoading(true)

        API_getdotadds(props.match.params.login)
        .then(data => {
            if (data!=undefined) {
                setdotadds(data)
            } 
        })
        .finally(() => setLoading(false));

    }, [update]);

    return (
            <Spin spinning={loading}>
                <Modal
                    okButtonProps={{ style: { display: 'none' } }}
                    title={`Добавить адрес`}
                    onCancel={resetModal}
                    visible={modalVisible}
                    >
                    <Form form={form2} onFinish={handleSubmit2} >
                    <Form.Item label="Адрес" name="dotadds" rules={[{required: true, message: 'введите Адрес'}]}>
                        <Input maxLength = {255} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Дополнительные сведения" name="dotname" rules={[{required: false, message: 'введите сведения'}]}>
                        <Input maxLength = {255} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 10 }}>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                    </Form>
                </Modal>  
                <Form className="che-form" onFinish={handleSubmit} form={form} layout='vertical'>
                    <PageTitle title="Профиль" />

                    <Form.Item label="Имя организации" name="name" rules={[{required: true, message: 'введите имя'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Имя магазина" name="magazin" rules={[{required: true, message: 'введите магазина'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="ИНН" name="inn" rules={[{required: true, message: 'введите инн'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="КПП" name="kpp" rules={[{required: false, message: 'введите кпп'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Номер счета" name="rs" rules={[{required: true, message: 'введите р/с'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Корреспондентский счет" name="ks" rules={[{required: true, message: 'введите к/с'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Наименование банка" name="bname" rules={[{required: true, message: 'введите наименование банка'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="БИК" name="bid" rules={[{required: true, message: 'введите БИК'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Населенный пункт" name="sity" rules={[{required: true, message: 'введите населенный пункт'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Адрес организации" name="adds" rules={[{required: true, message: 'введите адрес'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Номер телефона" name="phone" rules={[{required: true, message: 'введите номер телефона'}]}>
                        <InputMask mask="+7 (999) 999-99-99" className="ant-input" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{required: true, message: 'введите ваш email'}]}>
                        <Input maxLength = {250} autoComplete="off" type="email" disabled={true}/>
                    </Form.Item>
                        
                    <Form.Item label="Статус" name="status" rules={[{required: true, message: 'выберите статус'}]}>
                        <Select
                        value={statusId}
                        onChange={setstatusId}
                        placeholder="Выберите статус"
                        disabled={!godMode}
                        >
                            <Select.Option key="act" value="act">
                                Активный (А)
                            </Select.Option>
                            <Select.Option key="ban" value="ban">
                                Заблокирован
                            </Select.Option>
                            <Select.Option key="ogr" value="ogr">
                                Активный (Б)
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Тип документа для печати" name="docType" rules={[{required: true, message: 'выберите тип документа'}]}>
                        <Select
                        value={docTypeId}
                        onChange={setdocTypeId}
                        placeholder="Выберите тип документа"
                        disabled={!godMode}
                        >
                            <Select.Option key="type1" value="type1">
                                Обычная накладная
                            </Select.Option>
                            <Select.Option key="torg12" value="torg12">
                                ТОРГ-12
                            </Select.Option>
                            <Select.Option key="upd" value="upd">
                                УПД
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Меркурий" name="mercury" rules={[{required: true, message: 'выберите'}]}>
                        <Select
                        value={mercury}
                        onChange={setmercury}
                        placeholder="Меркурий да/нет?"
                        disabled={!godMode}
                        >
                            <Select.Option key="0" value="0">
                                Нет
                            </Select.Option>
                            <Select.Option key="1" value="1">
                                Да
                            </Select.Option>
                        </Select>
                    </Form.Item>             
                    {
                         DrawerV && <ChangePassword />
                    }
                    <Button disabled={dis} icon={<SaveOutlined />} htmlType="submit" type="primary">
                            Сохранить
                    </Button> 
                    <Button disabled={dis} icon={<KeyOutlined />} onClick={vizDrawerV} type="primary" style={{ marginLeft: "10px" }}>
                            Сменить пароль
                    </Button>

                    <PageTitle title="Адреса магазинов" style={{ marginTop: '20px' }}/>

                    <Form.Item>
                        <div align="right">
                            <Button style={{ marginBottom: '20px' }} icon={<PlusOutlined />} onClick={() => modalVis()} type="primary"> 
                                Добавить адрес
                            </Button>
                        </div>
                            <Table
                                columns={columns}
                                dataSource={dotadds}
                                rowClassName="row-hover"
                                bordered
                                size="small"
                            />
                    </Form.Item>

                </Form>
            </Spin>
    )
}

export default Profile;
