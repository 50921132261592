import axios from '../utils/axios';
import { notification } from 'antd';

export const API_uploadfile = json => {
    const url = `ib/upload`;
    return axios({
        method:  'POST',
        url: url,
        data: {
            fileBody: json.fileBody,
            id: json.id,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `Ошибка`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deletefile = fileID => {
    return axios({
        method: 'DELETE',
        url: `ib/deletefile/${fileID}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_downloadFile = idFile => {
    return axios({
        method: 'GET',
        url: `ib/download/${idFile}`,
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `file-${idFile}.jpg`);
        document.body.appendChild(link);
        link.click();
    });
};