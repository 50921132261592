import React from 'react';
import { Menu, Layout } from 'antd';
import Header from './main/Header';
import { withRouter } from 'react-router-dom';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Footer from './main/Footer';
import Market from '../pages/Market';
import Admin from './../pages/Admin';
import { BarcodeOutlined, ShoppingCartOutlined, CrownOutlined, BarsOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { API_getstatus } from '../api/status';
import Profile from '../pages/Profile';
import Cart from '../pages/Cart';
import Order from '../pages/Order'; 
import Product from '../pages/Product';
import ProductAdd from '../pages/ProductAdd'; 
import UserAdd from '../pages/UserAdd';

const Main = props => {

    const [godMode, setgodMode] = useState(false);

    useEffect(() => {
        const json = {
            userlogin : localStorage.getItem('login'),
        };

        API_getstatus(json)
        .then(data => {
            if (data!=undefined) {
                if (data[0].siteVersion != localStorage.getItem('siteVersion')) {
                    localStorage.setItem('siteVersion', data[0].siteVersion);
                    window.location.reload();
                }
                if (data[0].userType == 1) {
                    setgodMode(true)
                }
            }
        })
    }, []);


    return (
        <div className="wrapper watermarked">
            <div className="content">
                <Header history={props.history} />
                <Layout>
                        <Menu
                            mode="horizontal"
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >

                           {/*<Menu.Item key="/market">
                                
                                <NavLink to="/market">
                                    <BarcodeOutlined /> <span>Товары</span>
                                </NavLink>
                                
     
                            </Menu.Item>
                            <Menu.Item key="/order">
                                
                                <NavLink to="/order">
                                    <BarsOutlined /> <span>Мои заказы</span>
                                </NavLink>
                                
     
                            </Menu.Item>
                            <Menu.Item key="/cart">
                                
                                <NavLink to="/cart">
                                    <ShoppingCartOutlined /> <span>Корзина</span>
                                </NavLink>
                                
     
                            </Menu.Item>*/}

                            {
                            godMode &&

                            <Menu.Item key="/admin">
                                <NavLink to="/admin">
                                    <CrownOutlined  style={{ color : "red" }} /> <span>Админ</span>
                                </NavLink>
                            </Menu.Item>
                            }
                        </Menu>
                </Layout>   
                    <Layout style={{ padding: '0 24px 24px', background: '#FFF' }}>
                        <Route exact path="/" render={() => <Redirect to="/admin" />} />
                        <Switch>
                            <Route path="/market" component={Market} />
                            <Route path="/admin" component={Admin} /> 
                            <Route path="/profile/:login" component={Profile} />
                            <Route path="/cart" component={Cart} />
                            <Route path="/order" component={Order} />
                            <Route path="/product/:id" component={Product} />
                            <Route path="/productadd" component={ProductAdd} /> 
                            <Route path="/useradd" component={UserAdd} />
                        </Switch>
                    </Layout>
                
            </div>
            <Footer />
        </div>
    );
};

export default withRouter(props => <Main {...props} />);
