import axios from './../utils/axios';
import { notification } from 'antd';

export const API_printdocx = (orderId,docType,fileName) => {
    let url = `ib/printdocx?orderId=${orderId}&docType=${docType}`;

    return axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
    })
        .then(function(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.docx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_printdocx2 = (dDate) => {
    let url = `ib/printdocx2?dDate=${dDate}`;

    return axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
    })
        .then(function(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Накладные за ${dDate}.zip`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_printdocx3 = (dDate) => {
    let url = `ib/printdocx2?dDate=${dDate}&isSchet=true`;

    return axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
    })
        .then(function(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Счета за ${dDate}.zip`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};