import axios from '../utils/axios';
import { notification } from 'antd';

export const API_getstatus = (json) => {
    let url = `ib/getstatus?`;
    if (json) {
        if (json.userlogin) url += `&userlogin=${json.userlogin}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getdata = (json) => {
    let url = `ib/getdata?`;
    if (json) {
        if (json.tp) url += `&tp=${json.tp}`;
        if (json.p1) url += `&p1=${json.p1}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};