import React from 'react';
import { Form, Input, Button } from 'antd';
import { notification, Drawer, Modal, Spin, Select, Checkbox, DatePicker } from 'antd';
import { API_registration } from './../api/registration';
import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { API_checkreg } from './../api/registration';

const Registraton = props => {

    const [DrawerV, setDrawerV] = useState(true);
    const [loading, setLoading] = useState(false);
    const [btRegDis, setbtRegDis] = useState(true);
    const [BDay, setBDay] = useState(undefined); 

    const [showModal, setshowModal] = useState(false); 

    const modalVis = () => {
        setshowModal(!showModal)
    }

    const resetModal = () => {
        setshowModal(!showModal)
    }

    const Reg = values => {
                API_registration(
                    values.name, 
                    values.email, 
                    values.phone, 
                    values.password, 
                    values.inn,
                    values.kpp, 
                    values.sity, 
                    values.adds,
                    values.rs,
                    values.ks,
                    values.bid,
                    values.bname,
                    "type1",
                    "reg",
                    values.magazin,
                    "0",/*Не меркурий по умолчанию*/
                    )
                    .then(response => {
                        if (response.response==0) {
                            notification.error({ message: `С такой почтой уже зарегистрировались, введите другой email` });
                        }
                        else if (response.response==1) {
                            notification.error({ message: `Произошла ошибка при регистрации, пожалуйста попробуйте позже` });
                        }
                        else {
                            info()
                        }
                    })
                    .catch(
                        setDrawerV(false)
                    );
    };

    function info() {
        Modal.info({
          title: 'Регистрация',
          content: (
            <div>
              <p>Проверьте свой email. Вам выслано сообщение для подтверждения адреса электронной почты.</p>
            </div>
          ),
          onOk() {
            setDrawerV(false)
          },
        });
      }

      const onCheck = () => { 
            setbtRegDis(!btRegDis)
       }

    const DrawerClose = () => {
        setDrawerV(false)
      };
    
    const [form] = Form.useForm();

    const  compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('введенные вами пароли не совпадают');
        } else {
            callback();
        }
        };

    const  checkrus = (rule, value, callback) => {
        var regexp  = /[A-Za-z]/g;;
        if (value.search(regexp) != -1) {
            callback('Введите только русские буквы');
        } else {
            callback();
        }
    };

    const  chekemail = (rule, value, callback) => {
        API_checkreg(value)
        .then(response => {
            if (response.length>0) {
                if (value && response[0].email != 0) {
                    callback('Пользователь с таким email уже зарегистрирован. Введите другой email.');
                } else {
                    callback();
                }
            }
        })
        .catch(() => {
            notification.error({ message: 'Произошла ошибка, пожалуйста попробуйте позже' });
        });
    };

    return (
            <Drawer
            width={"80%"}
            title="Регистрация"
            visible={DrawerV}
            onClose={DrawerClose}
            bodyStyle={{ paddingBottom: 80 }}
            >
            <Modal
            title="Политика в отношении обработки персональных данных"
            visible={showModal}
            onOk={resetModal}
            onCancel={resetModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            centered
            width="80%"
            >
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://docs.google.com/document/d/1y5QLPrBXIAqobJoUgSFhMWEs33M8r7Ib6VxNKjisS5c/edit?usp=sharing" gesture="media"  allow="encrypted-media" allowfullscreen></iframe>
            </div>
            </Modal>
            <Spin spinning={loading}>
            <Form onFinish={Reg} layout='vertical' form={form}>  
            <Form.Item label="Имя организации" name="name" rules={[{required: true, message: 'введите имя'},]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="Имя магазина" name="magazin" rules={[{required: true, message: 'введите магазина'},]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="ИНН" name="inn" rules={[{required: true, message: 'введите инн'},]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="КПП" name="kpp" rules={[{required: false, message: 'введите кпп'}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="Номер счета" name="rs" rules={[{required: true, message: 'введите р/с'}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="Корреспондентский счет" name="ks" rules={[{required: true, message: 'введите к/с'}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="Наименование банка" name="bname" rules={[{required: true, message: 'введите наименование банка'}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="БИК" name="bid" rules={[{required: true, message: 'введите БИК'}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="Населенный пункт" name="sity" rules={[{required: true, message: 'введите населенный пункт'},{validator: checkrus}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="Адрес организации" name="adds" rules={[{required: true, message: 'введите адрес'},{validator: checkrus}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off"/>
            </Form.Item>
            <Form.Item label="Номер телефона" name="phone" rules={[{required: true, message: 'введите номер телефона'}]} hasFeedback>
                <InputMask mask="+7 (999) 999-99-99" className="ant-input"/>
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{required: true, message: 'введите ваш email'},{validator: chekemail}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off" type="email" />
            </Form.Item>
            <Form.Item label="Пароль" name="password" rules={[{required: true, message: 'введите пароль'}]} hasFeedback>
                <Input maxLength = {250} autoComplete="off" type="password" />
            </Form.Item>
            <Form.Item label="Повторите пароль" name="confirmpassword" rules={[{required: true, message: 'повторите пароль'},{validator: compareToFirstPassword}]} hasFeedback>
                <Input.Password />
            </Form.Item>
            <div align="left" class="divconf" >
                <Button style={{ marginTop: "10px" }} type="link" onClick={modalVis}>
                Политика в отношении обработки персональных данных
                </Button>
            </div>
            <div align="left">
            <Checkbox onChange={onCheck}>Согласен с политикой обработки персональных данных</Checkbox>
            </div>
            <Button style={{ marginTop: "20px" }} type="primary" htmlType="submit" disabled={btRegDis}>
                Зарегистрироваться
            </Button>
            </Form>
            </Spin>
            </Drawer>
    );
};

export default Registraton;