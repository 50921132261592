import React from 'react';
import { withRouter } from 'react-router-dom';
import LoginForm from './../components/LoginForm';
import { Carousel, Button, Drawer, Form, Statistic, Modal } from 'antd';
import { useState, useEffect } from 'react';
import Registration from './../components/Registration';
import Footer from './main/Footer';
import logo from './../images/logoIB.JPG';
import { API_activecod } from '../api/activatecod';

const Confirmemail = props => {

    const [text, settext] = useState(undefined);

    useEffect(() => {
        const json = {
            cod : props.match.params.cod,
        };

        API_activecod(json)
        .then(data => {
            if (data.id==1) {
                info1()
            }
            if (data.id==2) {
                info2()
            }
            if (data.id==0) {
                info3()
            }
        })
    }, []);

    function info2() {
        Modal.success({
          title: 'Информация',
          content: (
            <div>
              <p>Вы уже подтвердили Ваш email. Заявка на регистрацию принята. После проверки мы вышлем вам приглашение на ваш email.</p>
            </div>
          ),
          onOk() {
            props.history.push('/login')
          },
        });
      }

      function info1() {
        Modal.success({
          title: 'Информация',
          content: (
            <div>
              <p>Вы подтвердили Ваш email. Заявка на регистрацию принята. После проверки мы вышлем вам приглашение на ваш email.</p>
            </div>
          ),
          onOk() {
            props.history.push('/login')
          },
        });
      }

      function info3() {
        Modal.error({
          title: 'Информация',
          content: (
            <div>
              <p>Произошла ошибка, пожалуйста попробуйте позже</p>
            </div>
          ),
          onOk() {
            props.history.push('/login')
          },
        });
      }

    return (
        <div>
            <div align="center" style={{ marginTop: '20px'}}>
                <img src={logo} width="200px" height="200px" alt="Logo" />
            </div>
 
        </div>
    );
};

export default withRouter(props => <Confirmemail {...props} />);
