import React from 'react';
import { withRouter } from 'react-router-dom';
import LoginForm from './../components/LoginForm';
import { Carousel, Button, Drawer, Form, Statistic } from 'antd';
import { useState, useEffect } from 'react';
import Registration from './../components/Registration';
import Footer from './main/Footer';
import { YoutubeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import logo from './../images/logoIB.JPG';

const Login = props => {

    function getbrowserversion() {

        var nAgt = navigator.userAgent;
        var browserName  = navigator.appName;
        var fullVersion  = ''+parseFloat(navigator.appVersion); 
        var majorVersion = parseInt(navigator.appVersion,10);
        var nameOffset,verOffset,ix; 
        
        
        // In Opera 15+, the true version is after "OPR/" 
        if ((verOffset=nAgt.indexOf("OPR/"))!==-1) {
         browserName = "Opera";
         fullVersion = nAgt.substring(verOffset+4);
        }
        // In older Opera, the true version is after "Opera" or after "Version"
        else if ((verOffset=nAgt.indexOf("Opera"))!==-1) {
         browserName = "Opera";
         fullVersion = nAgt.substring(verOffset+6);
         if ((verOffset=nAgt.indexOf("Version"))!==-1) 
           fullVersion = nAgt.substring(verOffset+8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
         browserName = "Microsoft Internet Explorer";
         fullVersion = nAgt.substring(verOffset+5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
         browserName = "Chrome";
         fullVersion = nAgt.substring(verOffset+7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
         browserName = "Safari";
         fullVersion = nAgt.substring(verOffset+7);
         if ((verOffset=nAgt.indexOf("Version"))!==-1) 
           fullVersion = nAgt.substring(verOffset+8);
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
         browserName = "Firefox";
         fullVersion = nAgt.substring(verOffset+8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
                  (verOffset=nAgt.lastIndexOf('/')) ) 
        {
         browserName = nAgt.substring(nameOffset,verOffset);
         fullVersion = nAgt.substring(verOffset+1);
         if (browserName.toLowerCase()===browserName.toUpperCase()) {
          browserName = navigator.appName;
         }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix=fullVersion.indexOf(";"))!==-1)
           fullVersion=fullVersion.substring(0,ix);
        if ((ix=fullVersion.indexOf(" "))!==-1)
           fullVersion=fullVersion.substring(0,ix);
        
        majorVersion = parseInt(''+fullVersion,10);
        if (isNaN(majorVersion)) {
         fullVersion  = ''+parseFloat(navigator.appVersion); 
         majorVersion = parseInt(navigator.appVersion,10);
        }
        var result=[]
        result['b']=browserName;
        result['v']=majorVersion;
        return result;
        }
        

    const [DrawerLogin, setDrawerLogin] = useState(false);

    var moment = require('moment');

    const regLogin = () => {

        setDrawerLogin(!DrawerLogin)

    }

    const DrawerClose = () => {
        setDrawerLogin(false)
      };

    const [DrawerReg, setDrawerReg] = useState(false);

    const regBack = () => {

        setDrawerReg(!DrawerReg)

    }

    const { Countdown } = Statistic;

    var versions=getbrowserversion();

    var support=0;

    if ((versions['b']==="Chrome")&&(versions['v']>53))  support=1;
    if ((versions['b']==="Firefox")&&(versions['v']>54))  support=1;
    if ((versions['b']==="Opera")&&(versions['v']>38))  support=1;
    if ((versions['b']==="Safari")&&(versions['v']>12)) support=1;

    if (support===0)  {
    return (
        <div>Ваш браузер не поддерживается или устарел, установите последние обновления или используйте другой браузер Chrome/Firefox/Opera/Safari</div>
    ) } else
    return (
        <div className="wrapper watermarked">
            <div className="content">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginBottom: 100,
                        }}
                    > 
                        <Form className="che-form">
                        <div align="center" style={{ marginTop: '20px'}}>
                            <img src={logo} width="200px" height="200px" alt="Logo" />
                        </div>
                            {/*
                        <div style={{padding: '0 20px', fontSize: '28px', marginTop: '10px' }}>
                            <p align="center" style={{marginBottom: '20px'}}>ᛕᛟᛖᚢᚣᚺᛋᚱ ᚢᛟ ᚢᚹᛟᚦᚣᛯᛊ ƃⰓᛆⰓᛆᚹᚢᚣᚷᚣ</p>
                            <p align="center" style={{marginBottom: '20px'}}>ƃⰓᛆⰓᛆᚹᚢᚣᚷ ᛟᛠ ᛋᚢ ƃᛟᚹᛋᛈᛟᛒ ᛈ.ᛈ. - ᛒᛕᚴᛈ ᚺᚣᛈᛠᛟᚱⰞᛧᛊᛚᛟ ᚱᛕᚴᛠᛈᛕᛟᛚᛟ ᚢᚹᚣ℥ᚦᚺᛋᛕᚣ!</p>
                            <p align="center" style={{marginBottom: '20px'}}>ᚹᛊᛯᛋᛖ ᚹᚣƃᛟᛠⰓᛆ ᛊᛯᛊᚦᚺᛊᛒᚺᛟ ᛈ 09:00 ᚦᛟ 18:00  +7964-421-55-92</p>



                        </div>
                            */}
                        </Form>
                        <Drawer
                            width={"80%"}
                            title="Авторизация"
                            visible={DrawerLogin}
                            onClose={DrawerClose}
                            bodyStyle={{ paddingBottom: 80 }}
                            >
                            <LoginForm history={props.history} />
                        </Drawer>
                        <div style={{display: 'flex'}}>
                            {/*
                            <Button disabled = {false} type="primary" onClick={regBack} style={{ marginTop: '30px'}}>
                              Регистрация
                            </Button>
                            */}
                            <Button type="primary" onClick={regLogin} style={{ marginTop: '30px', marginLeft: '20px'}}>
                              Войти
                            </Button>
                        </div>
                        {
                            DrawerReg && <Registration />
                        }
                    </div>
                </div>

            <Footer/>    
        </div>
    );
};

export default withRouter(props => <Login {...props} />);
