import React from 'react'
import { Tabs, Form, Table, Spin, Select, Input, Button, Space, Radio } from 'antd'
import PageTitle from '../components/PageTitle';
import AdminProduct from '../components/AdminProduct'; 
import AdminUsers from '../components/AdminUsers';
import AdminOrders from '../components/AdminOrders'; 
import AdminStatistic from '../components/AdminStatistic';
import AdminHolydays from '../components/AdminHolydays'; 
import AdminPays from '../components/AdminPays';
import { useState, useEffect } from 'react'; 
import { API_getstatus } from '../api/status';


const Admin = (props) => {

    const { TabPane } = Tabs;

    const [loading, setLoading] = useState(false);

    const [companyId, setcompanyId] = useState(Number(localStorage.getItem('companyId')));

    const onChange1 = ({ target: { value } }) => {
        setcompanyId(value);
        localStorage.setItem('companyId', value);
    };

    const plainOptions = [
        {
            label: 'ИП Борисов С.С.',
            value: 1,
        },
        {
            label: 'ИП Борисова',
            value: 2,
        },
    ];

    useEffect(() => {

        setLoading(true)

        const json = {
            userlogin : localStorage.getItem('login'),
        };

        API_getstatus(json)

        .then(data => {
            if (data!=undefined) {
                
                if (data[0].siteVersion != localStorage.getItem('siteVersion')) {
                    localStorage.setItem('siteVersion', data[0].siteVersion);
                    window.location.reload();
                }
                if (data[0].userStatus == 0) {
                    localStorage.removeItem('accessToken')
                    props.history.push('/login')
                }
                if (data[0].userType != 1) {
                    localStorage.removeItem('accessToken')
                    props.history.push('/login')
                }
            }
        })
        .finally(() => setLoading(false));
        
    }, []);

    

    function callback(key) {
        localStorage.setItem('defaultActiveKey', key);
    }

    return (
        <Spin spinning={loading}>
            <Form>
                <PageTitle style={{ marginTop: 20 }} title="Администратор" />

                {/*<Radio.Group options={plainOptions} onChange={onChange1} value={companyId} />*/}

                <Tabs defaultActiveKey={localStorage.getItem('defaultActiveKey')} onChange={callback}>
                    <TabPane tab="Заказы" key="1">
                        <AdminOrders history={props.history} />
                    </TabPane>
                    <TabPane tab="Товары" key="2">
                        <AdminProduct history={props.history} />
                    </TabPane>
                    <TabPane tab="Пользователи" key="3">
                        <AdminUsers history={props.history}/>
                    </TabPane>
                    <TabPane tab="Статистика" key="4">
                        <AdminStatistic history={props.history}/>
                    </TabPane>
                    <TabPane tab="Выходные дни" key="5">
                        <AdminHolydays history={props.history}/>
                    </TabPane>
                    <TabPane tab="Способ оплаты" key="6">
                        <AdminPays history={props.history}/>
                    </TabPane>
                </Tabs>

            </Form>
        </Spin>
    )
}

export default Admin;