import axios from './../utils/axios';
import { notification } from 'antd';

export const API_getproduct = type => {
    return axios({
        method: 'GET',
        url: `ib/getproduct`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getdataproduct = id => {
    return axios({
        method: 'GET',
        url: `ib/getproduct/${id}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_updateproduct = json => {
    return axios({
        method: 'POST',
        url: `ib/updateproduct`,
        data: {
            id: json.id,
            name: json.name,
            description: json.description,
            price: json.price,
            mr: json.mr,
            link: json.link,
            tp: json.tp,
            st: json.st,
            ndoc: json.ndoc,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_addproduct = json => {
    return axios({
        method: 'POST',
        url: `ib/addproduct`,
        data: {
            name: json.name,
            description: json.description,
            price: json.price,
            mr: json.mr,
            tp: json.tp,
            ndoc: json.ndoc,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getproductall = type => {
    return axios({
        method: 'GET',
        url: `ib/getproductall`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
}; 

export const API_addProductAdm = json => {
    return axios({
        method: 'GET',
        url: `ib/orderproductadd/${json.productId}/${json.oCode}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};