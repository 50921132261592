import React from 'react';
import { Badge } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'

const CartTitle = props => { 

    return (
        <div align="right">
                <Link to={`/cart`}>
                    <Badge count={props.cartCnt} offset={[5, -7]}>
                        <p><ShoppingCartOutlined style={{ color : "red" }}/> Корзина</p>
                    </Badge>
                </Link>
        </div>
    );
};

export default CartTitle;