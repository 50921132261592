import XLSX from 'xlsx'

export const generateXlsx = (JsonData,ExcelFileName,ExcelColumns,ExcelHName,ExcelFName) => {
    try{
        var arrCol = new Array();
        var arrField = new Array();
        var wscols = new Array();
        var i = 0;
        for (var key in ExcelColumns) {
            var obj = ExcelColumns[i];
                if (key=ExcelHName) {
                    var headobj = obj[key];
                    arrCol.push(obj[key]);
                }
                if (key=ExcelFName) {
                    arrField.push(obj[key]);
                }
                if (key="width") {
                  var width = parseInt(obj[key], 10)/5;
                  wscols.push({wch : width});
                }
                if (key="children") {
                    var child = ExcelColumns[i].children;
                    var x = 0;  
                    var p = 1;
                    for (var childkey in child) {
                        if (p==1) {
                            arrCol.pop();
                            arrField.pop();
                        }
                        var childobj = child[x];
                        if (childkey=ExcelHName) {
                            arrCol.push(headobj +" / "+ childobj[childkey]);
                        }
                        if (childkey=ExcelFName) {
                            arrField.push(childobj[childkey]);
                        }
                        if (childkey="width") {
                          var chwidth = parseInt(childobj[childkey], 10)/5;
                          wscols.push({wch : chwidth});
                        }
                    x++; p++;
                    }
                }
            i++;
        }
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(JsonData,{header: arrField});
        for (var k = 0; k < arrCol.length; k++) {
            ws[XLSX.utils.encode_cell({r: 0, c: k})] = {t: 's', v: arrCol[k]};
        }
        for (var r = 0; r < wscols.length; r++) {
            var wscolsjs = parseInt(wscols[r].wch, 10) || 0;
            if (wscolsjs == 0) {
              wscols[r] = {wch : 9};
            }
        }
        ws['!cols'] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, "Отчет");
        XLSX.writeFile(wb, ExcelFileName);
        }
        catch (e) {
            alert("jsontoxlsx.js: "+e.name+" "+e.message);
    }
};
