import React from 'react'
import PageTitle from './../components/PageTitle';
import { Form, Input, Spin, Button, notification, Select } from 'antd';
import { API_registration } from './../api/registration';
import { useState, useEffect } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { API_getstatus } from '../api/status';

const UserAdd = (props) => {

    const [dis, setdis] = useState(true);

    const [loading, setLoading] = useState(false);

    const [godMode, setgodMode] = useState(false);

    const [statusId, setstatusId] = useState(undefined);

    const [docTypeId, setdocTypeId] = useState(undefined);

    const handleSubmit = values => {
                setLoading(true);
                API_registration(
                    values.name, 
                    values.email, 
                    values.phone, 
                    values.password, 
                    values.inn,
                    values.kpp, 
                    values.sity, 
                    values.adds,
                    values.rs,
                    values.ks,
                    values.bid,
                    values.bname,
                    docTypeId,
                    "add",
                    values.magazin,
                    "0",/*Не меркурий по умолчанию*/
                    )
                    .then(function(response) {
                        if (response!=undefined) {
                            if (response.response==3) {
                                notification.success({ message: `Успешно зарегистрирован` });
                                props.history.push('/admin')
                            } else {
                                notification.error({ message: `Произошла ошибка при регистрации, пожалуйста попробуйте позже` });
                            }
                    }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const [form] = Form.useForm();

    useEffect(() => {

        if (props.match.params.login==localStorage.getItem('login')) {
            setdis(false)
        }
        const json = {
            userlogin : localStorage.getItem('login'),
        };

        API_getstatus(json)

        .then(data => {
            if (data!=undefined) {
                if (data[0].siteVersion != localStorage.getItem('siteVersion')) {
                    localStorage.setItem('siteVersion', data[0].siteVersion);
                    window.location.reload();
                }
                if (data[0].userStatus == 0) {
                    localStorage.removeItem('accessToken')
                    props.history.push('/login')
                }
                if (data[0].userType == 1) {
                    setgodMode(true)
                    setdis(false)
                }
                if (data[0].userType != 1) {
                    if (props.match.params.login!=localStorage.getItem('login')) {
                        localStorage.removeItem('accessToken')
                        props.history.push('/login')
                    }
                }
            }
        })
    }, []);

    return (
            <Spin spinning={loading}>
                <Form className="che-form" onFinish={handleSubmit} form={form} layout='vertical'>
                    <PageTitle title="Профиль" />

                    <Form.Item label="Имя организации" name="name" rules={[{required: true, message: 'введите имя'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Имя магазина" name="magazin" rules={[{required: true, message: 'введите магазина'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="ИНН" name="inn" rules={[{required: true, message: 'введите инн'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="КПП" name="kpp" rules={[{required: false, message: 'введите кпп'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Номер счета" name="rs" rules={[{required: true, message: 'введите р/с'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Корреспондентский счет" name="ks" rules={[{required: true, message: 'введите к/с'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Наименование банка" name="bname" rules={[{required: true, message: 'введите наименование банка'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="БИК" name="bid" rules={[{required: true, message: 'введите БИК'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Населенный пункт" name="sity" rules={[{required: true, message: 'введите населенный пункт'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Адрес организации" name="adds" rules={[{required: true, message: 'введите адрес'}]}>
                        <Input maxLength = {250} autoComplete="off" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Номер телефона" name="phone" rules={[{required: true, message: 'введите номер телефона'}]}>
                        <InputMask mask="+7 (999) 999-99-99" className="ant-input" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{required: true, message: 'введите ваш email'}]}>
                        <Input maxLength = {250} autoComplete="off" type="email" disabled={dis}/>
                    </Form.Item>
                    <Form.Item label="Пароль" name="password" rules={[{required: true, message: 'введите пароль'}]}>
                        <Input maxLength = {250} autoComplete="off" type="password" />
                    </Form.Item>
                    <Form.Item label="Тип документа для печати" name="docType" rules={[{required: true, message: 'выберите тип документа'}]}>
                        <Select
                        value={docTypeId}
                        onChange={setdocTypeId}
                        placeholder="Выберите тип документа"
                        disabled={!godMode}
                        >
                            <Select.Option key="type1" value="type1">
                                Обычная налкадная
                            </Select.Option>
                            <Select.Option key="torg12" value="torg12">
                                Торг-12
                            </Select.Option>
                            <Select.Option key="upd" value="upd">
                                Упд
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Button disabled={dis} icon={<SaveOutlined />} htmlType="submit" type="primary">
                            Сохранить
                    </Button> 

                </Form>
            </Spin>
    )
}

export default UserAdd;
