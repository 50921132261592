import axios from './../utils/axios';
import { notification } from 'antd';

export const API_cartAdd = json => {
    return axios({
        method: 'POST',
        url: `ib/cartadd`,
        data: {
            login: json.login,
            productId: json.productId,
            cnt: json.cnt,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_cartUpdate = json => {
    return axios({
        method: 'POST',
        url: `ib/cartupdate`,
        data: {
            cartId: json.cartId,
            cnt: json.cnt,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deletecart = json => {
    return axios({
        method: 'DELETE',
        url: `ib/cartdelete/${json.cartId}`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_GetCartCnt = json => {
    return axios({
        method: 'GET',
        url: `ib/getcartcnt`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getcartdata = json => {
    return axios({
        method: 'GET',
        url: `ib/getcartdata`,
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};
