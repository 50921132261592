import React from 'react'
import { Select, Radio, Input, DatePicker, Modal, Button, Form, Table, Spin, Tooltip as Tooltip2, Popconfirm, notification, InputNumber } from 'antd'
import PageTitle from '../components/PageTitle';
import { useState, useEffect } from 'react'; 
import { API_getcartdata, API_deletecart, API_cartUpdate } from '../api/cart';
import {  DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { API_createorder } from '../api/order'; 
import { API_getdata } from '../api/status';
import { API_getholydays } from '../api/holyday';
import { API_getdotadds } from './../api/dotadds';

const Cart = (props) => {

    const [loading, setLoading] = useState(false);
    const [cartDate, setcartDate] = useState(undefined);
    const [buttonDis, setbuttonDis] = useState(true);

    const [cartData, setcartData] = useState([]);
    const [cartSum, setcartSum] = useState(0);
    const [update, setupdate] = useState(false); 
    const [holydays, setholydays] = useState([]);
    const [m1, setm1] = useState([]);

    const [Checked1, setChecked1] = useState(true);
    const [Checked2, setChecked2] = useState(true);

    const [dotAddsSt, setdotAddsSt] = useState(undefined);

    const [dotAdds, setdotAdds] = useState([]);

    const columns = [
        {
          title: 'Товар',
          dataIndex: 'name',
          key: 'name',
        },
        {
            title: 'Цена руб.',
            dataIndex: 'price',
            key: 'price',
          },
        {
          title: 'Количество',
          render: row => {
            return (    
                <InputNumber size = "small" min={1} max={10000} defaultValue={row.cnt} onChange={onChangeCount(row.cartId)} />
            )
          }
        },
        {
            title: 'Сумма руб.',
            dataIndex: 'sm',
            key: 'sm',
          },
        {
            title: 'Действия',
            render: row => {
            return (    
                <Tooltip2 title="Удалить из корзины">
                    <Popconfirm title="Вы действительно хотите удалить？" placement="bottom" onConfirm={() => confirm(row.cartId)} okText="Да" cancelText="Нет">
                        <a><DeleteOutlined style={{ color: 'red' }}/></a>   
                    </Popconfirm>
                </Tooltip2>
            )
          }
        },
  
      ];

    const CreateOrder = values => {

        setLoading(true);

        const json = {
            dDate: cartDate,
            adds: dotAddsSt,
            comment: values.comment,
            opl: Number(values.opl),
            login: localStorage.getItem('login'),
        };
        API_createorder(json)
            .then(function(response) {
                if (response.id==1) {
                    info()
                }
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
            .finally(() => setLoading(false));

    } 
      

    function confirm(cartId) {
        setLoading(true);
                const json = {
                    cartId: cartId,
                };
                API_deletecart(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Удалено' });
                            setcartSum(0)
                            setbuttonDis(true)
                            setupdate(!update)
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
      }

    const onChangeCount = (cartId) => value => {

    if (value>0) {

        setLoading(true)

        const json = {
            cartId: cartId,
            cnt: value,
        };

        API_cartUpdate(json)
        .then(data => {

                setupdate(!update)

        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
            throw Error;
        })
        .finally(() => setLoading(false));
    }

    }


    useEffect(() => {

        setLoading(true)

        API_getcartdata()
        .then(data => {
            if (data!==undefined) {
                setcartData(data)
                setcartSum(data[0].smA)

                if (Number(data[0].smA)>0) {
                    setbuttonDis(false)
                } else {
                    setbuttonDis(true)
                }
            } 
        })
        .finally(() => setLoading(false));
    }, [update]);

    useEffect(() => {

        const json1 = {
            tp: "getPay1",
            p1: 1,
        };

        setLoading(true)

        API_getdata(json1)
        .then(data => {
            if (data!=undefined) {
                if (Number(data.dt)==1) {
                    setChecked1(true)
                } else {
                    setChecked1(false)
                }
            } 
        })
        .finally(() => setLoading(false));

        const json2 = {
            tp: "getPay2",
            p1: 1,
        };

        setLoading(true)

        API_getdata(json2)
        .then(data => {
            if (data!=undefined) {
                if (Number(data.dt)==1) {
                    setChecked2(true)
                } else {
                    setChecked2(false)
                }
            } 
        })
        .finally(() => setLoading(false));

    }, []);

    useEffect(() => {

        setLoading(true)

        API_getholydays()
                .then(data => {
                    if (data!=undefined) {
                        setholydays(data)

                        {data.map(h => (
                            m1.push(h.hd)
                        ))}
                    }
                })
                .catch(function(error) {
                    try {
                        notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                    } catch {
                        notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                    }
                    throw Error;
                })
                .finally(() => setLoading(false));

    }, []);

    function info() {
        Modal.success({
          title: 'Спасибо за заказ',
          content: (
            <div>
              <p>Ждем вас снова</p>
            </div>
          ),
          onOk() {
            props.history.push('/order')
          },
        });
      }

    function onChange(date, dateString) {

        setcartDate(dateString);

      }

    function disabledDate(current) {
        var currentDate

        if (moment()>moment().startOf('day').add(10, 'hours')) {
            currentDate=moment()
        } else {
            currentDate=moment().subtract(1, 'days')
        }

        return current && (current < currentDate || moment(current).format( 'dddd' ) == "Sunday" || moment(current).format( 'dddd' ) == "Saturday" || m1.includes(moment(current).format("YYYY-MM-DD"))==true);

      }

    const [form] = Form.useForm();

    useEffect(() => {

        setLoading(true)

        API_getdotadds(localStorage.getItem('login'))
        .then(data => {
            if (data!=undefined) {
                setdotAdds(data)
            } 
        })
        .finally(() => setLoading(false));

    }, []);


    return (
        <Spin spinning={loading}>
            <Form className="che-form" form={form} onFinish={CreateOrder}

            >
                <PageTitle style={{ marginTop: 20 }} title="Корзина" />
                <Table 
                pagination={false} 
                dataSource={cartData} 
                columns={columns} 
                footer={() => Number(cartSum)>0 ? <div align="right" ><b>Общая сумма:{` `+cartSum+` `} рублей</b></div> : null }
                />
                <Form.Item style={{ marginTop: 10 }} label="Выберите дату доставки:" name="cartDate" rules={[{required: true, message: 'выберите дату доставки'}]} >
                <DatePicker 
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    onChange={onChange} />
                </Form.Item>
                <Form.Item label="Выберите адрес доставки" style={{ marginBottom: 0 }} 
                    name="adds" rules={[{required: true, message: 'Выберите адрес доставки. Если адреса нет то добавьте его в настройке профиля магазина'}]}>
                        <Select
                        value={dotAddsSt}
                        onChange={setdotAddsSt}
                        placeholder="Выберите адрес доставки"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {dotAdds && dotAdds.map(pr => (
                            <Select.Option key={pr.dotAdds} value={pr.dotAdds}>
                                {'Адрес: '+pr.dotAdds+' Доп.инфо: '+pr.dotName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                <Form.Item label="Оплата" style={{ marginBottom: 10 }} 
                name="opl" rules={[{required: true, message: 'выберите тип оплаты'}]}>
                    <Radio.Group >
                        <Radio disabled={!Checked1} value={1}>Наличный расчет</Radio> 
                        <Radio disabled={!Checked2} value={2}>Безналичный расчет</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item style={{ marginTop: 10 }} label="Комментарий к заказу" name="comment" rules={[{required: false, message: 'введите адрес'}]} >
                    <Input maxLength = {255} autoComplete="off"/>
                </Form.Item>
                <div align="right">
                <Button disabled={buttonDis} htmlType="submit" type="primary" style={{ marginTop: 10, marginRight: 5}}>Сделать заказ</Button>
                </div>
            </Form>
        </Spin>
    )
}

export default Cart;